import React, { useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import QuotationDocumentContent from "./QuotationDocumentContent";

const QuotationDocument = ({
  closeModal,
  quotation,
  loadQuotation,
  loader,
  setLoader,
}) => {
  const componentRef = useRef();
  const contentRef = useRef();
  // const printContent = (e) => {
  //   contentRef.current &&
  //     contentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //   // section.scrollIntoView({ behavior: "smooth", top: offsetTop });
  //   setTimeout(() => {
  //     window.print();
  //     document.title="asdasdasd"
  //   }, 1000);
  // };

  const termsAndConditions = {
    "Air Freight": [
      "Validity of this Quotation is as indicated above.",
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      "Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Shipments containing hazardous or restricted items must comply with International Air Transport Association (IATA) regulations. Additional charges and documentation requirements may apply.",
      " Charges quoted are subjected to changes according to ACTUAL Cargo details & Operations requirements.",
      " Rates & Services proposals are made based on capacity conditions at time of enquiry & subjected to changes according to market conditions.",
      " Subjected to Carrier’s Rates, Schedules, Space & Acceptance at time of ACTUAL Booking.",
      " Transit times are indicative only & subjected to changes according to airline’s routing & schedules. Neither VML nor the Airline guarantees specific delivery timelines.",
      "Customs Clearance at the destination is consignee’s responsibility unless otherwise specified. Any duties, taxes and related charges are EXCLUDED unless explicitly mentioned in the quotation.",
      " Cargo Marine Insurance coverage is not included in the quoted rates unless specifically requested and agreed upon. Liability for loss, damage, or delay is limited to the terms outlined by the airline or applicable conventions.",
      " Airline may impose cancellation fees, which will be charged back accordingly, if applicable.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      "By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    "International Courier Services": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Customs duties, taxes or other destination country charges are not included and is payable by Consignee, unless specified.",
      " Any other additional services required, will be quoted & charged accordingly.",
      " Transit times are estimated and subjected to changes according to Carrier’s schedule, routing and or any other unforeseen delays. Neither VML nor the Carrier guarantees exact delivery timelines.",
      " Shipments must comply with International Courier Carrier’s guidelines for prohibited & restricted items. VML & Carrier reserves the right to refuse any shipment not in compliance.",
      " Cargo Marine Insurance is not included unless specifically requested by client at time of enquiry. In the absence of such insurance, liability for loss or damage is limited as per Carrier’s terms & conditions.",
      " Liability of loss, damage, delay is limited to the terms & conditions outlined by Carrier.",
      'VML is not liable for delays, losses, or damages caused by force majeure "events", including but not limited to natural disasters, strikes, or political unrest.',
      " Any cancellation or change requests must be communicated prior to shipment dispatched. Additional fees may apply for such requests.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    "Sea Freight": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Hazardous or restricted cargo must comply with the International Maritime Dangerous Goods (IMDG) Code. Additional charges and documentation may apply for such shipments.",
      " Bookings are subjected to final confirmation based on carrier’s space availability, schedule & operational requirements at time of shipment.",
      " Transit times are estimated and subjected to changes due to port congestion, weather, customs clearance and or any other unforeseen circumstances. Neither VML nor the Carrier guarantees specific delivery timelines.",
      " Customs duties, taxes or other origin & destination country charges are not included and is payable by Consignee, unless specified.",
      " The client is responsible for providing accurate & complete documentation for the shipment. Any delays or penalties resulting from incorrect or missing documents are the client’s responsibility.",
      " Any other additional services required, will be quoted & charged accordingly.",
      " Cargo Marine Insurance is not included unless specifically requested by client at time of enquiry. In the absence of such insurance, liability for loss or damage is limited as per Carrier’s terms & conditions.",
      " Liability of loss, damage, delay is limited to the terms & conditions outlined by Carrier/ Shipping Line.",
      " Carrier/ Shipping Line may impose cancellation fees, which will be charged back accordingly, if applicable.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    "Road – Cross Border Trucking": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided(qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Transit times are estimated and subjected to changes due to traffic, customs clearance, weather and or any other unforeseen delays.Neither VML nor the Carrier guarantees exact delivery timelines.",
      " Customs duties, taxes or other origin & destination country charges are not included and is payable by Consignee, unless specified.",
      " The client is responsible for providing accurate & complete documentation for the shipment.Any delays or penalties resulting from incorrect or missing documents are the client’s responsibility.",
      " The client must inform VML of any Hazardous or restricted goods included in the shipment.Additional charges may apply for such cargo.",
      " Any other additional services required, will be quoted & charged accordingly.",
      " Cargo Marine Insurance is not included unless specifically requested by client at time of enquiry.In the absence of such insurance, liability for loss or damage is limited as per Carrier’s terms & conditions.",
      " Liability of loss, damage, delay is limited to the terms & conditions outlined by Carrier.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions.A copy of which, is available upon request.",
    ],
    "Road – Transportation/ OBD": [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " Any standby, waiting time, port charges etc., will be charged, if applicable.",
      " Any other additional equipment/ services required, will be quoted & charged accordingly.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      " VML’s liability ends once cargo is signed clean by recipient",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
    Others: [
      " Quotation is applicable for General & Normal Sized Cargo only, unless otherwise stated.",
      " Quoted rates are based on the shipment details provided (qty, weight, dimensions & destination) and are subjected to changes according to ACTUAL shipment details.",
      " This quotation is applicable only to the services described above.",
      " Any other additional equipment/ services required, will be quoted & charged accordingly.",
      " VML is not liable for delays, losses, or damages caused by force majeure events, including but not limited to natural disasters, strikes, or political unrest.",
      " By confirming this quotation, the client acknowledges and agrees to these Terms & Conditions.",
      " All business conducted by VML is subjected to our Standard Trading Terms & Conditions. A copy of which, is available upon request.",
    ],
  };

  const getDocumentTitle = () => {
    // User figures out what the correct reference is
    return document.title;
  };

  useEffect(() => {
    loadQuotation();
  }, []);

  return (
    <div className="h-[90vh] flex py-1 bg-white" ref={contentRef}>
      <div className="flex relative gap-2 w-full h-full overflow-y-auto">
       
        <QuotationDocumentContent
          quotation={quotation}
          componentRef={componentRef}
        />
      </div>

      <div className="flex flex-col gap-2  top-2 right-5 bg-white py-2 px-2">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              className="inline-flex w-40 float-right justify-center items-center gap-2 py-1 px-2 border-2 border-sky-600 shadow-sm text-xs font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            >
              <img src="print.svg" className="h-5" /> Print
            </button>
          )}
          pageStyle={`
            @media print {
              @page {
                size: portrait;
                margin: 30px !important;
                padding: 30px !important
                margin-top:30px !important
                
                background-color: rgb(245 245 245) !important;
              }
              body {
                margin: 0;
                background-color:rgb(245 245 245/1) !important;
                -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
                print-color-adjust: exact !important; /* Firefox and Edge */
            }
               .noBorder{
                    border: none !important;
                    min-height: 1060px;
                }
            }
     
          `}
          documentTitle="Quote"
          content={() => componentRef.current}
        />

        <button
          type="button"
          className="inline-flex float-right justify-center items-center gap-2 py-1 px-2 border-2 border-black shadow-sm text-xs font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
          onClick={() => closeModal()}
        >
          <img src="x.svg" className="h-5" /> Close
        </button>
      </div>
    </div>
  );
};

export default QuotationDocument;
