import React, { useState } from "react";
import "../../../index.scss";
import AutoResizeTextarea from "./AutoResizeTextArea";

function ManifestOrDeliveryNote({
  job,
  docId,
  handleChange,
  handlePackageChange,
  handlePoChange,
  handleAreaChange,
}) {
  let totalquantity = 0;
  let totalweight = 0;
  let totalChargableWeight = 0;
  let totalVolume = 0;
  job &&
    job.poes.map((po) => {
      totalquantity = totalquantity + parseInt(po.package.colli);
      totalweight =
        totalweight + parseFloat(po.package.weight * po.package.colli);
      totalChargableWeight =
        totalChargableWeight +
        parseFloat(po.package?.chargableWeight * po.package.colli);
      totalVolume =
        totalVolume + parseFloat(po.package?.volume * po.package.colli);
    });

  const [isEditable, setIsEditable] = useState(true);
  return (
    <div>
      <div
        class="w-[950px] min-h-[690px] relative land border-2 border-black px-3 py-3"
        id="print_div"
      >
        <div class="flex justify-end w-full">
          <img src="logo.png" alt="voyagelogo" className="h-20" />
        </div>
        <div class="w-full text-center text-lg font-medium pb-3">
          <h2>
            {docId === "2"
              ? "DELIVERY NOTE"
              : docId === "4"
              ? "MANIFEST/ PACKING LIST"
              : docId === "5"
              ? "JOB INSTRUCTIONS - SEAFREIGHT"
              : null}
          </h2>
        </div>
        <div class="flex w-full justify-between min-h-[130px] mb-1">
          <div class="flex flex-col text-xs font-bold w-1/2">
            {docId === "2" ? (
              <div class="flex mb-2">
                <label className={`${docId === "2" ? "w-48" : "w-24"}`}>
                  CUSTOMER
                </label>
                :&nbsp;
                <input
                  class="font-normal w-full editable"
                  name="customerName"
                  onChange={handleChange}
                  value={job?.customerName}
                />
              </div>
            ) : docId === "4" ? (
              <div class="flex mb-2 w-full">
                <label class="w-32">SHIPPER</label>
                :&nbsp;
                <input
                  class="font-normal w-full editable"
                  name="shipperName"
                  onChange={handleChange}
                  value={job?.shipperName}
                  contentEditable={isEditable}
                />
              </div>
            ) : null}
            {docId === "2" || docId === "5" ? (
              <div class="flex mb-2">
                <label
                  class={`${
                    docId === "5" ? "w-56" : docId === "2" ? "w-48" : "w-24"
                  }`}
                >
                  VESSEL NAME
                </label>
                :&nbsp;
                <div class="font-normal w-full">
                  <input
                    class="font-normal w-full editable"
                    name="vesselName"
                    onChange={handleChange}
                    value={job?.vesselName}
                  />
                </div>
              </div>
            ) : null}
            {docId === "4" ? (
              <div class="flex mb-2 w-full">
                <label class="w-32"> CONSIGNEE</label>
                :&nbsp;
                <div class="flex flex-col w-full font-normal h-24">
                  <input
                    class="font-normal w-full editable"
                    name="consigneeName"
                    onChange={handleChange}
                    value={job?.consigneeName}
                  />
                  <textarea
                    class="font-normal w-full resize-none overflow-clip text-clip focus:ring-0 p-0 leading-3 text-xs border-0 editable"
                    name="consigneeAddress"
                    rows={6}
                    onChange={(e) => handleAreaChange(e, 6)}
                    value={job?.consigneeAddress}
                  />
                </div>
              </div>
            ) : null}
            {docId === "4" ? (
              <div class="flex mb-2 w-full">
                <label class="w-32">MB/L NO</label>:&nbsp;
                <div class="font-normal w-full">
                  <input
                    class="font-normal w-full editable"
                    name="awb"
                    onChange={handleChange}
                    value={job?.awb}
                  />
                </div>
              </div>
            ) : null}
            {docId === "2" ? (
              <div class="flex mt-2">
                <label class="w-48">
                  VESSEL'S AGENT/
                  <br />
                  DELIVERY ADDRESS
                </label>
                :&nbsp;
                <div class="font-normal w-full">
                  <textarea
                    class="font-normal w-full resize-none overflow-clip border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                    name="consigneeAddress"
                    rows={6}
                    onChange={(e) => handleAreaChange(e, 6)}
                    value={job?.consigneeAddress}
                  />
                </div>
              </div>
            ) : docId === "5" ? (
              <div class="flex mb-2">
                <label class="w-56 h-20">COLLECTION ADDRESS</label>
                :&nbsp;
                <div class="font-normal w-full">
                  <textarea
                    class="font-normal resize-none overflow-clip border-0 focus:ring-0 p-0 leading-3 text-xs w-full editable"
                    name="collectionAddress"
                    rows={6}
                    onChange={(e) => handleAreaChange(e, 6)}
                    value={job?.collectionAddress}
                  />
                </div>
              </div>
            ) : null}
            {docId === "2" ? (
              <div class="flex mt-2">
                <label class="w-32">DELIVERY DATE</label>
                :&nbsp;
                <div class="font-normal w-full">
                  <input
                    class="font-normal w-full editable"
                    name="etd"
                    onChange={handleChange}
                    value={job?.etd}
                  />
                </div>
              </div>
            ) : docId === "5" ? (
              <div class="flex mb-2">
                <label class="w-32">DELIVER TO</label>
                :&nbsp;
                <div class="font-normal w-full">
                  <textarea
                    class="font-normal resize-none overflow-clip border-0 focus:ring-0 p-0 leading-3 text-xs w-full editable"
                    name="deliveryAddress"
                    rows={4}
                    onChange={(e) => handleAreaChange(e, 4)}
                    value={job?.deliveryAddress}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div
            class={`flex flex-col text-xs font-bold h-full ${
              docId === "4" && "gap-5"
            }`}
          >
            <div className="flex flex-col ">
              <div class="flex mb-1">
                <label class={`${docId !== "4" ? "w-20" : "w-28"}`}>
                  JOB NO
                </label>
                :&nbsp;&nbsp;
                <input
                  class="font-normal w-44 editable"
                  name="jobNo"
                  onChange={handleChange}
                  value={job?.jobNo}
                />
              </div>
              {docId !== "2" ? (
                <div class="flex mb-1">
                  <label class={`${docId === "4" ? "w-28" : "w-20"}`}>
                    ORIGIN
                  </label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal w-44 editable"
                    name="origin"
                    onChange={handleChange}
                    value={job?.origin}
                  />
                </div>
              ) : null}
              {docId !== "2" ? (
                <div class="flex mb-1">
                  <label class={`${docId === "4" ? "w-28" : "w-20"}`}>
                    DESTIN
                  </label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal w-44 editable"
                    name="destination"
                    onChange={handleChange}
                    value={job?.destination}
                  />
                </div>
              ) : null}
            </div>
            {docId === "2" ? (
              <div className="flex flex-col ">
                <div class="flex mb-1">
                  <label class="w-28"> VESSEL</label>
                  :&nbsp;&nbsp;
                  <textarea
                    class="font-normal resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                    name="vessel"
                    rows={6}
                    onChange={(e) => handleAreaChange(e, 6)}
                    value={job?.vessel}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-28"> REF NO</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal w-full editable"
                    name="refNo"
                    onChange={handleChange}
                    value={job?.refNo}
                  />
                </div>
              </div>
            ) : docId === "4" ? (
              <div className="flex flex-col">
                <div class="flex mb-1">
                  <label class="w-28">FLT/VESSEL DTLS</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal w-44 editable"
                    name="fltDetails"
                    onChange={handleChange}
                    value={job?.fltDetails}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-28"> VOYAGE NO</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal w-44 editable"
                    name="voyage"
                    onChange={handleChange}
                    value={job?.voyage}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-28">ETD ORIGIN</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal w-44 editable"
                    name="etd"
                    onChange={handleChange}
                    value={job?.etd}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-28"> ETA DESTINATION</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal w-44 editable"
                    name="eta"
                    onChange={handleChange}
                    value={job?.eta}
                  />
                </div>
              </div>
            ) : docId === "5" ? (
              <div className="flex flex-col">
                <div class="flex mb-1">
                  <label class="w-20">BKG REF:</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal editable"
                    name="fltDetails"
                    onChange={handleChange}
                    value={job?.fltDetails}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-20"> VESSEL</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal editable"
                    name="vesselName"
                    onChange={handleChange}
                    value={job?.seaVessel}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-20"> VOYAGE NO</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal editable"
                    name="voyage"
                    onChange={handleChange}
                    value={job?.voyage}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-20">ETD ORIGIN</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal editable"
                    name="etd"
                    onChange={handleChange}
                    value={job?.etd}
                  />
                </div>
                <div class="flex mb-1">
                  <label class="w-20"> ETA DESTIN</label>
                  :&nbsp;&nbsp;
                  <input
                    class="font-normal editable"
                    name="eta"
                    onChange={handleChange}
                    value={job?.eta}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div class="w-full">
          <table class="w-full text-xs pb-20">
            <thead class="bg-neutral-500 text-white w-full border border-black">
              <tr className="h-5 p-0">
                <th className="p-1">Vessel</th>
                <th className="p-1">Po No</th>
                <th className="p-1 w-3">Supplier</th>
                <th className="p-1">MAWB/ BL/ REF NUMBER</th>
                <th className="p-1">
                  Qty
                  <br />
                </th>
                <th className="p-1">
                  Weight
                  <br />
                  (kgs)
                </th>
                <th className="p-1">
                  Length
                  <br />
                  (cms)
                </th>
                <th className="p-1">
                  width
                  <br />
                  (cms)
                </th>
                <th className="p-1">
                  height
                  <br />
                  (cms)
                </th>
                <th className="p-1">
                  ch. wt.
                  <br />
                  (kgs)
                </th>
                <th className="p-1">
                  vol.
                  <br />
                  (M3)
                </th>
              </tr>
            </thead>
            <tbody class="text-xs">
              {job &&
                job.poes.map((po, index) => (
                  <tr key={index} class="h-fit min-h-fit border-black border">
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.vesselName}
                        handleChange={handlePoChange}
                        name="vesselName"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-full"
                        poId={po.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.poNo}
                        handleChange={handlePoChange}
                        name="poNo"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-32"
                        poId={po.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.supplierName}
                        handleChange={handlePoChange}
                        name="supplierName"
                        style="font-normal resize-none overflow-clip h-fit py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-32"
                        poId={po.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.shipmentRefNo}
                        handleChange={handlePoChange}
                        name="shipmentRefNo"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-full"
                        poId={po.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.package.colli}
                        handleChange={handlePackageChange}
                        name="colli"
                        style="font-normal resize-none overflow-clip text-xs h-auto py-1 border-0 focus:ring-0 p-0 w-8"
                        poId={po.id}
                        packageId={po.package.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.package.weight}
                        handleChange={handlePackageChange}
                        name="weight"
                        style="font-normal resize-none overflow-clip text-xs h-auto py-1 border-0 focus:ring-0 p-0 w-8"
                        poId={po.id}
                        packageId={po.package.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.package.length}
                        handleChange={handlePackageChange}
                        name="length"
                        style="font-normal resize-none overflow-clip text-xs h-auto py-1 border-0 focus:ring-0 p-0 w-8"
                        poId={po.id}
                        packageId={po.package.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.package.width}
                        handleChange={handlePackageChange}
                        name="width"
                        style="font-normal resize-none overflow-clip text-xs h-auto py-1 border-0 focus:ring-0 p-0 w-8"
                        poId={po.id}
                        packageId={po.package.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.package.height}
                        handleChange={handlePackageChange}
                        name="height"
                        style="font-normal resize-none overflow-clip text-xs h-auto py-1 border-0 focus:ring-0 p-0 w-8"
                        poId={po.id}
                        packageId={po.package.id}
                      />
                    </td>
                    <td class="border-l h-auto align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po.package.chargableWeight?.toFixed(2)}
                        handleChange={handlePackageChange}
                        type="number"
                        name="chargableWeight"
                        style="font-normal resize-none overflow-clip text-xs h-auto py-1 border-0 focus:ring-0 p-0 w-10"
                        poId={po.id}
                        packageId={po.package.id}
                      />
                    </td>
                    <td class="border-l align-middle pl-1 pt-1 text-xs editable border-black editable">
                      <AutoResizeTextarea
                        text={po?.package.volume.toFixed(2)}
                        handleChange={handlePackageChange}
                        type="number"
                        name="volume"
                        style="font-normal resize-none overflow-clip text-xs h-auto py-1 border-0 focus:ring-0 p-0 w-10"
                        poId={po.id}
                        packageId={po.package.id}
                      />
                    </td>
                  </tr>
                ))}
              <tr class="h-9 font-bold">
                <td></td>
                <td></td>
                <td>TOTAL:</td>
                <td></td>
                <td>{totalquantity}</td>
                <td>{totalweight}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {totalChargableWeight && totalChargableWeight.toFixed(2)}
                </td>
                <td>{totalVolume && totalVolume.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
          <div class="flex text-xs font-bold pt-2 w-auto gap-5">
            <div>PACKED INTO:</div>
            <div className="flex gap-2">
              <input
                class="font-semibold w-8 editable"
                name="packedPallets"
                onChange={handleChange}
                value={job?.packedPallets}
              />
              PALLETS
              {docId === "2" ? "/PACKAGE" : null}
            </div>
          </div>
          {docId === "3" ? (
            <div class="text-xs font-bold pt-1 flex justify-between w-56">
              <div>GROSS WEIGHT:</div>
              <input
                class="font-normal editable"
                name="totalweight"
                onChange={handleChange}
                value={job?.totalweight}
              />
              KGS
            </div>
          ) : null}
          {docId === "4" || docId === "5" ? (
            <div class="text-xs font-bold pt-1 flex justify-between w-56">
              <div>DIMENSION:</div>&nbsp;
              <div>
                <input
                  class="font-normal w-20 editable"
                  name="dimension"
                  onChange={handleChange}
                  value={job?.dimension}
                />
                CM
              </div>
            </div>
          ) : null}
          {docId === "2" || docId === "3" || docId === "5" ? (
            <div class="text-xs font-bold pt-5 flex gap-2">
              <div>REMARKS:</div>
              <textarea
                class="font-normal resize-none overflow-clip w-1/2 border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                name="remarksFreeText"
                rows={5}
                onChange={(e) => handleAreaChange(e, 5)}
                value={job?.remarksFreeText}
              />
            </div>
          ) : null}
        </div>
        {docId === "2" || docId === "3" ? (
          <div>
            <div class="text-xs font-bold underline">
              RECEIVED IN GOOD ORDER & CONDITION:
              <span className="editable" contentEditable="isEditable"></span>
            </div>
            <div class="text-xs flex gap-40 pt-16 mb-5">
              <div class="font-bold border-t border-dashed border-black">
                <div>VESSEL'S STAMP/ SIGNATURE</div>
              </div>
              <div class="font-bold border-t border-dashed border-black">
                DATE/ TIME
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ManifestOrDeliveryNote;
