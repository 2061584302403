import { getter } from "@progress/kendo-react-common";
import React, { useContext, useState, useEffect } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import axios from "axios";
import { BASE_URL, movementStatuses } from "../../constants";
import { formatDate } from "@telerik/kendo-intl";
import AppContext from "../../context/app-context";
import { CustomColumnMenu } from "../CollumnMenu";
import "./PoPopupStyle.scss";

const DATA_ITEM_KEY = "package.id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const columns = [
  {
    field: "poNo",
    title: "Po No",
    show: true,
    width: 150,
    filterable: true,
  },
  {
    field: "vesselName",
    title: "Vessel",
    show: true,
    width: 150,
    filterable: true,
  },
  {
    field: "customerName",
    title: "Customer",
    show: true,
    width: 350,
    filterable: false,
  },
  {
    field: "PackageLWH",
    title: "Package",
    show: true,
    width: 80,
    filterable: false,
  },
  {
    field: "package.colli",
    title: "Quantity",
    show: true,
    width: 100,
    filterable: false,
  },
  {
    field: "package.location",
    title: "Location",
    show: true,
    width: 80,
    filterable: false,
  },
];

const PoPopupList = ({ closeModal, setJob, job, setModified }) => {
  const [polist, setPoList] = useState([]);
  const [gridData, setGridData] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 10,
  });

  const [stateColumns, setStateColumns] = React.useState(columns);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const handleGridDataStateChange = (e) => {
    setGridData(e.dataState);
  };

  const [selectedState, setSelectedState] = React.useState({});
  const [selectedPos, setSelectedPos] = useState([]);

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      const index = selectedPos.findIndex(
        (x) => x.package.id === event.dataItem.package.id
      );

      if (index >= 0) {
        setSelectedPos((selectedPos) =>
          selectedPos.filter((_, i) => i !== index)
        );
      } else {
        setSelectedPos((oldArray) => [...oldArray, event.dataItem]);
      }
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedPos(event.dataItems);
    setSelectedState(newSelectedState);
  }, []);

  const loadData = async () => {
    const location =
      job.jobType === 400 ? job.origin : job.poes[0]?.package?.location;
    const res = await axios.get(
      `${BASE_URL}/Cargo/PoByLocation?location=${location}&pageNo=0&pageSize=10`
    );
    let data = res.data.data;
    setPoList(
      data
        .filter((x) => !job.poes.some((y) => y.package?.id === x.package?.id))
        .map((d) => ({
          ...d,
          // packageId: d.package.id,
          PackageLWH:
            d.package.length + "X" + d.package.width + "X" + d.package.height,
        }))
    );
  };

  const onSavePo = () => {
    setModified(true);
    setJob({
      ...job,
      poes: [...job.poes, ...selectedPos],
      packages: [
        ...job.packages,
        ...selectedPos.map((p) => ({
          cargoId: p.id,
          packageID: p.package.id,
        })),
      ],
    });
    closeModal();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="w-full flex justify-between items-center ">
        <div className="text-sm font-medium capitalize">Select PO's</div>
        <div className="flex gap-2">
          <button
            type="button"
            className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-sky-600 shadow-sm text-sm font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            onClick={onSavePo}
          >
            Save
          </button>
          <button
            type="button"
            className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-black shadow-sm text-sm font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            onClick={() => closeModal()}
          >
            Close
          </button>
        </div>
      </div>
      <div className="overflow-hidden importPo rounded-lg shadow-[0_0_4px_rgba(0,0,0,0.1)] border border-slate-300 mt-3">
        <Grid
          data={process(
            polist.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            })),
            gridData
          )}
          pageable={{
            pageSizes: true,
          }}
          filterable={true}
          sortable={true}
          resizable={true}
          reorderable={true}
          onDataStateChange={handleGridDataStateChange}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          {...gridData}
          style={{
            borderRadius: "10px",
            border: "2px",
            borderColor: "#E5E7EB",
            fontSize: "3px",
            fontFamily: "poppins",
            height: "50vh",
            width: "70vw",
            marginTop: "4px",
          }}
        >
          <GridColumn
            filterable={false}
            width="65px"
            field={SELECTED_FIELD}
            headerSelectionValue={
              polist.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }
          />
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <GridColumn
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filterable={column.filterable}
                  cell={column.cell}
                  selectable={true}
                  columnMenu={(props) => (
                    <CustomColumnMenu
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
        </Grid>
      </div>
    </div>
  );
};

export default PoPopupList;