import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManifestOrDeliveryNote from "./Documents/ManifestOrDeliveryNote";
import Mbl from "./Documents/Mbl";
import PerformaInvoice from "./Documents/PerformaInvoice";
import VmlCargoLabel from "./Documents/VmlCargoLabel";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Hbl from "./Documents/Hbl";
import AppContext from "../../context/app-context";
import { formatDate } from "@telerik/kendo-intl";
import "../../index.scss";
import ReactToPrint from "react-to-print";
//import jsPDF from "jspdf";

const PrintDoc = () => {
  const authCtx = useContext(AppContext);
  const navigate = useNavigate();
  let { jobId, poId, docid } = useParams();
  const [initialJob, setInitialJob] = useState();
  const [job, setJob] = useState();
  const [po, setPo] = useState();
  const [poTotal, setPoTotal] = useState();
  const [load, setLoad] = useState(false);
  const [jobNo, setJobNo] = useState();
  const contentRef = useRef(null);
  const componentRef = React.useRef();

  const printContent = (e) => {
    contentRef.current &&
      contentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    // section.scrollIntoView({ behavior: "smooth", top: offsetTop });
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  const getUniqueObjects = (arr) => {
    return arr.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );
  };

  const onLoad = async () => {
    if (jobId !== undefined) {
      try {
        const res = await axios.get(`${BASE_URL}/Job/${jobId}`);
        const data = {
          ...res.data,
          poes: res.data.poes.filter((x) => x.package !== null),

        };
        setJobNo(data.jobNo);
        setInitialJob(data);
        setPoValuesTotal(data);
        getLocalStorageData(data);
      } catch (err) {
        console.log(err);
        if (err.response?.status === 400) toast.error(err.response.data);
        else toast.error("An error occured");
      }
    } else if (poId !== undefined) {
      try {
        const res = await axios.get(`${BASE_URL}/cargo/${poId}`);
        const data = res.data;
        setPo(data);
      } catch (err) {
        if (err.response?.status === 400) toast.error(err.response.data);
        else toast.error("An error occured");
      }
    }
  };

  const getLocalStorageData = (data) => {
    let localData;
    if (docid === "2") {
      localData = JSON.parse(
        localStorage.getItem(`${data.jobNo}-deliveryNote`)
      );
    } else if (docid === "4") {
      localData = JSON.parse(localStorage.getItem(`${data.jobNo}-packingList`));
    } else if (docid === "5") {
      localData = JSON.parse(
        localStorage.getItem(`${data.jobNo}-jobInstruction`)
      );
    } else if (docid === "6") {
      localData = JSON.parse(
        localStorage.getItem(`${data.jobNo}-performaInvoice`)
      );
    } else if (docid === "7") {
      localData = JSON.parse(localStorage.getItem(`${data.jobNo}-BLDraft`));
    } else if (docid === "8") {
      localData = JSON.parse(localStorage.getItem(`${data.jobNo}-HBL`));
    }
    if (localData && localData !== undefined) {
      setJob(localData);
    } else {
      setJob({
        ...data,
        eta: data.eta ? formatDate(new Date(data.eta), "dd-MM-yyyy") : "",
        etd: data.etd ? formatDate(new Date(data.etd), "dd-MM-yyyy") : "",
        vessel: data.vesselName,
        deliveryOfGoods: `${
          data.destinationAgentName ? data.destinationAgentName : ""
        }${
          data.destinationAgentName && data.destinationAgentAddress
            ? "," + data.destinationAgentAddress
            : ""
        }`,
      });
    }
  };

  const setPoValuesTotal = (job) => {
    if (job && job.poes && job.poes.length > 0) {
      setPoTotal({
        totalQuantity: job.poes.reduce(
          (total, value) => total + (value.package?.colli || 0),
          0
        ),
        totalChWeight: job.poes
          .reduce(
            (total, value) => total + (value.package?.chargableWeight || 0),
            0
          )
          ?.toFixed(3),
        totalVolume: job.poes
          ?.reduce((total, value) => total + (value.package?.volume || 0), 0)
          ?.toFixed(3),
        totalWeight: job.poes
          ?.reduce((total, value) => total + (value.package?.weight || 0), 0)
          ?.toFixed(3),
        totalColli: job.poes.reduce(
          (total, value) => total + (value.package?.colli || 0),
          0
        ),
      });
    }
    setLoad(true);
  };

  const resetDocumentData = () => {
    setJob(initialJob);
    if (docid === "2") {
      localStorage.removeItem(`${jobNo}-deliveryNote`);
    } else if (docid === "4") {
      localStorage.removeItem(`${jobNo}-packingList`);
    } else if (docid === "5") {
      localStorage.removeItem(`${jobNo}-jobInstruction`);
    } else if (docid === "6") {
      localStorage.removeItem(`${jobNo}-performaInvoice`);
    } else if (docid === "7") {
      localStorage.removeItem(`${jobNo}-BLDraft`);
    } else if (docid === "8") {
      localStorage.removeItem(`${jobNo}-HBL`);
    }
    toast.success("Document reset successfully");
  };

  const saveDocumentData = () => {
    if (docid === "2") {
      localStorage.setItem(`${jobNo}-deliveryNote`, JSON.stringify(job));
    } else if (docid === "4") {
      localStorage.setItem(`${jobNo}-packingList`, JSON.stringify(job));
    } else if (docid === "5") {
      localStorage.setItem(`${jobNo}-jobInstruction`, JSON.stringify(job));
    } else if (docid === "6") {
      localStorage.setItem(`${jobNo}-performaInvoice`, JSON.stringify(job));
    } else if (docid === "7") {
      localStorage.setItem(`${jobNo}-BLDraft`, JSON.stringify(job));
    } else if (docid === "8") {
      localStorage.setItem(`${jobNo}-HBL`, JSON.stringify(job));
    }
    toast.success("Document saved");
  };

  const handleChange = (e) => {
    console.log(e);
    setJob({ ...job, [e.target.name]: e.target.value });
  };

  const handlePoChange = (e, id) => {
    setJob({
      ...job,
      poes: [
        ...job.poes.map((po) =>
          po.id === id ? { ...po, [e.target.name]: e.target.value } : po
        ),
      ],
    });
  };

  const handlePackageChange = (e, id, type, packageId) => {
    let value = e.target.value;
    value = value === "" ? 0 : value;
    if (type === "number") {
      value = parseFloat(value);
    }
    setJob({
      ...job,
      poes: [
        ...job.poes.map((po, poId) =>
          po.id === id && po.package.id === packageId
            ? {
                ...po,
                package: { ...po.package, [e.target.name]: value },
              }
            : po
        ),
      ],
    });
  };

  const handleAreaChange = (e, rows) => {
    const inputValue = e.target.value;
    const inputRows = inputValue.split("\n").length;
    if (inputRows <= rows) {
      setJob({ ...job, [e.target.name]: e.target.value });
    }
  };

  const onCloseClick = (e) => {
    if (docid === "9") {
      navigate(`/po/${poId}`);
    } else {
      navigate(`/job/${jobId}`);
    }
  };
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div
      className="absolute h-screen w-screen z-50 top-0 right-0 bg-gray-200 overflow-scroll"
      id="up"
    >
      <div className="flex justify-center p-5 relative" ref={contentRef}>
        <div className=" bg-white w-fit p-16 prt" ref={componentRef}>
          <header className="hidden">
            Printed By: {authCtx.profile.displayname}
          </header>
          {load === true &&
          (docid === "2" || docid === "4" || docid === "5") ? (
            <>
              <ManifestOrDeliveryNote
                job={job}
                docId={docid}
                handleChange={handleChange}
                handlePackageChange={handlePackageChange}
                handlePoChange={handlePoChange}
                handleAreaChange={handleAreaChange}
              />
              <style type="text/css" media="print">
                {
                  " @page { size: landscape; }\
                  "
                }
              </style>
            </>
          ) : docid === "6" ? (
            <>
              <PerformaInvoice
                job={job}
                handleChange={handleChange}
                handlePoChange={handlePoChange}
                handleAreaChange={handleAreaChange}
              />
              <style type="text/css" media="print">
                {
                  "@page { size: portrait; }\
                  "
                }
              </style>
            </>
          ) : docid === "7" ? (
            <>
              <Mbl
                job={job}
                poTotal={poTotal}
                handleChange={handleChange}
                handleAreaChange={handleAreaChange}
              />
              <style type="text/css" media="print">
                {
                  "@page { size: portrait; }\
                  "
                }
              </style>
            </>
          ) : docid === "8" ? (
            <>
              <Hbl
                job={job}
                poTotal={poTotal}
                handleChange={handleChange}
                handleAreaChange={handleAreaChange}
              />
              <style type="text/css" media="print">
                {
                  "@page { size: portrait; }\
                  "
                }
              </style>
            </>
          ) : docid === "9" ? (
            <>
              <VmlCargoLabel job={job} po={po} />
              <style type="text/css" media="print">
                {
                  "@page { size: landscape; }\
                  "
                }
              </style>
            </>
          ) : null}
        </div>

        <div class="absolute top-6 right-6 flex justify-center items-center no-print">
          <button
            type="button"
            onClick={() => resetDocumentData()}
            class="bg-orange-300 flex items-center gap-1 text-yellow-900 py-2 px-4 rounded-lg shadow hover:shadow-xl hover:bg-orange-300 duration-300 w-24 mr-4 h-10 disabled:opacity-25 disabled:cursor-not-allowed"
            disabled={jobNo === null || jobNo === undefined}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3"
              />
            </svg>
            Reset
          </button>
          <button
            type="button"
            onClick={() => saveDocumentData()}
            class="bg-green-400 flex items-center gap-1 text-yellow-900 py-2 px-4 rounded-lg shadow hover:shadow-xl hover:bg-green-300 duration-300 w-24 mr-4 h-10 disabled:opacity-25 disabled:cursor-not-allowed"
            disabled={jobNo === null || jobNo === undefined}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9"
              />
            </svg>
            Save
          </button>
          <ReactToPrint
            trigger={() => (
              <button
                type="button"
                onClick={(e) => printContent(e)}
                class=" bg-green-200 flex items-center gap-1 text-yellow-900 py-2 px-4 rounded-lg shadow hover:shadow-xl hover:bg-green-300 duration-300 w-24 mr-4 h-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                  />
                </svg>
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
          <button
            onClick={onCloseClick}
            class=" bg-red-200 flex items-center gap-1 text-yellow-900 py-2 px-4 rounded-lg shadow hover:shadow-xl hover:bg-red-400 duration-300 w-24 h-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-7 h-7"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Close
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default PrintDoc;
