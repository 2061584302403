import React, { useContext, useEffect, useRef, useState } from "react";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import ShipmentDetails from "./ShipmentDetails";
import axios from "axios";
import { BASE_URL, jobTypes, shipmentModes } from "../../constants";
import CargoImportPopup from "./CargoImportPopup";
import ReactModal from "react-modal";
import QuotationDocument from "./QuatationDocument";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import KendoLoader from "../KendoLoader";
import { debounce } from "lodash";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import ManualModePopup from "./ManualModePopup";
import SendMailModal from "./SendMailModal";

const CreateQuotation = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "1px solid #231f20",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      // width: "40%",
      // marginTop: "50px",
      // marginBottom: "100px",
      overflow: "hidden",
      transform: "translate(-50%, -50%)",
    },
  };
  const defaultService = {
    id: Date.now().toString(),
    expectedDate: new Date(),
    quantity: 0,
    price: 0,
    customClarence: "",
    title: "",
    details: [],
  };
  const defaultOption = {
    id: Date.now().toString(),
    origin: "",
    destination: "",
    expectedDate: new Date(),
    status: "Draft",
    isNew: true,
    comment: "",
    optionTitle: "",
    cargoDetailsOptions: {
      id: Date.now().toString(),
      quantity: 0,
      length: 0,
      width: 0,
      height: 0,
      grossWeight: 0,
      grossVolume: 0,
      chargeWeight: 0,
      weight: 0,
      mode: "",
      cargoes: [],
    },
    services: [defaultService],
  };
  const defaultShipment = {
    mode: "Air",
    options: [defaultOption],
  };
  const defaultForm = {
    id: "new",
    customer: null,
    originPort: null,
    destinationPort: null,
    jobType: null,
    modes: [],
    currency: null,
    quotationDate: new Date(),
    validityDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
    cargoDetails: {
      quantity: 0,
      length: 0,
      width: 0,
      height: 0,
      grossWeight: 0,
      grossVolume: 0,
      chargeWeight: 0,
      mode: "0",
      cargoes: [],
      packages: [],
    },
    shipment: [],
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const [quotation, setQuotation] = useState(defaultForm);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [originPorts, setOriginPorts] = useState([]);
  const [destinPorts, setDestinPorts] = useState([]);
  const [selectedMode, setSelectedMode] = useState();
  const [customers, setCustomers] = useState([]);
  const [logisticServices, setLogisticServices] = useState([]);
  const [modalIsOpen, setModelIsOpen] = useState(false);
  const [printModalIsOpen, setPrintModelIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const inputRefs = useRef({});
  const InvalidRef = useRef([]);
  const [manualModeModalOpen, setManualModeModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quotationPdf, setQuotationPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isManualMode = quotation?.cargoDetails?.mode === "1";

  const filterList = debounce(async (e) => {
    let res;
    let searchText;
    if (e.filter.value === "") searchText = "~ALL";
    else searchText = e.filter.value;
    switch (e.target.name) {
      case "customer":
        res = await axios.get(
          `${BASE_URL}/Lookup/LimitedCustomers?searchText=${searchText}`
        );
        setCustomers(res.data);
        break;
      case "originPort":
        res = await axios.get(
          `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=10&countryCode=${null}`
        );
        setOriginPorts(res.data);
        break;
      case "destinationPort":
        res = await axios.get(
          `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=10&countryCode=${null}`
        );
        setDestinPorts(res.data);
        break;
      case "vessel":
        res = await axios.get(
          `${BASE_URL}/Lookup/Vessels?searchText=${searchText}&take=20`
        );
        setVessels(res.data);
        break;
      case "currency":
        if (searchText === "~ALL") setCurrencies(allCurrencies);
        else {
          const results = allCurrencies.filter(
            (currency) =>
              currency.code.toLowerCase().includes(searchText.toLowerCase()) ||
              currency.name.toLowerCase().includes(searchText.toLowerCase())
          );

          setCurrencies(results);
        }
        break;

      default:
        break;
    }
  }, 300);

  const validateForm = () => {
    const invalid = [];

    // Check for mandatory fields
    if (!quotation.customer) invalid.push("customer");
    if (!quotation.originPort) invalid.push("originPort");
    if (!quotation.destinationPort) invalid.push("destinationPort");
    if (!quotation.currency) invalid.push("currency");
    if (quotation.modes.length === 0) invalid.push("modes");
    if (
      !quotation.cargoDetails?.vessels ||
      quotation.cargoDetails.vessels.length === 0
    ) {
      invalid.push("vessel");
    }

    // Validate shipment, options, and services dynamically
    let isServiceInvalid = false; // Flag to track if any service has invalid details

    if (quotation.shipment?.length > 0) {
      // Loop through all shipments
      for (const shipment of quotation.shipment) {
        if (shipment?.options?.length > 0) {
          // Loop through all options in each shipment
          for (const option of shipment.options) {
            if (option?.services?.length > 0) {
              // Loop through all services in each option
              for (const service of option.services) {
                // Check if service details is empty or undefined
                if (!service.details || service.details.length === 0) {
                  isServiceInvalid = true;
                  invalid.push("service");
                  break; // Exit loop as we already found an invalid service
                }
              }
            }
          }
        }
      }
    }

    // If no service is selected, show toast message
    if (isServiceInvalid) {
      toast.warning("Please select at least one service for each shipment.");
    }

    // Store invalid fields in useRef
    setInvalidFields(invalid);

    // Scroll to the first invalid field (if any invalid fields)
    if (invalid.length > 0) {
      scrollToInvalidField(invalid[0]);
    }

    // Return if the form is valid
    return invalid.length === 0;
  };

  const handleToggle = (status) => {
    setQuotation((pre) => ({
      ...pre,
      cargoDetails: {
        ...pre.cargoDetails,
        mode: status ? "1" : "0",
        cargoes: status ? pre.cargoDetails?.cargoes ?? [] : null,
        packages: !status ? pre.cargoDetails?.packages ?? [] : null,
      },
    }));
  };

  const scrollToInvalidField = (fieldName) => {
    const field = inputRefs.current[fieldName];
    if (field) {
      field.focus();
      field.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  function getOrdinal(num) {
    if (num === 1) return num + "st";
    if (num === 2) return num + "nd";
    if (num === 3) return num + "rd";
    return num + "th";
  }

  const validateServices = (data) => {
    for (const shipment of data.shipment) {
      for (const option of shipment.options) {
        for (const service of option.services) {
          if (!service.details || service.details.length === 0) {
            const mode = shipmentModes.find((x) => x.id === shipment.mode);

            const optionIndex = shipment.options.findIndex(
              (x) => x.id === option.id
            );
            const locationIndex = option.services.findIndex(
              (x) => x.id === service.id
            );
            return {
              isValid: false,
              message: `Please select at least one service in ${
                mode.mode
              } option ${optionIndex + 1} for ${getOrdinal(
                locationIndex + 1
              )} location `,
            };
          }
          if (
            !service.originPortId ||
            !service.destinationPortId ||
            service.originPortId === "" ||
            service.destinationPortId === ""
          ) {
            const mode = shipmentModes.find((x) => x.id === shipment.mode);
            const optionIndex = shipment.options.findIndex(
              (x) => x.id === option.id
            );
            const locationIndex = option.services.findIndex(
              (x) => x.id === service.id
            );
            return {
              isValid: false,
              message: `Please select origin and destination port in ${
                mode.mode
              } option ${optionIndex + 1} for ${getOrdinal(
                locationIndex + 1
              )} location `,
            };
          }
          const emptyServiceDetail = service.details.find(
            (x) => x.serviceId === null
          );
          if (emptyServiceDetail) {
            const mode = shipmentModes.find((x) => x.id === shipment.mode);
            const optionIndex = shipment.options.findIndex(
              (x) => x.id === option.id
            );
            const locationIndex = option.services.findIndex(
              (x) => x.id === service.id
            );
            return {
              isValid: false,
              message: `All services in ${mode.mode} option ${
                optionIndex + 1
              } for the ${getOrdinal(
                locationIndex + 1
              )} location must be filled `,
            };
          }
        }
      }
    }
    // If all services have at least one detail, return valid
    return {
      isValid: true,
    };
  };
  // useEffect(() => {
  //   validateForm();
  // }, [quotation]);

  const handleOnCancel = () => {
    navigate(`/quotations`);
  };

  const handleSave = async () => {
    if (!validateForm()) {
      toast.warning("Please fill in all mandatory fields.");
      return;
    }
    const vaildate = validateServices(quotation);
    if (!vaildate.isValid) {
      toast.warning(vaildate.message);
      return;
    }
    console.log(quotation);
    let payload = {
      ...quotation,
      jobType: quotation?.jobType?.id,
      modes: quotation.modes.map((x) => x.id),
      currencyCode: quotation.currency?.code,
      cargoDetails: {
        ...quotation.cargoDetails,
        vessels:
          quotation.cargoDetails?.vessels?.map((vessel) => ({
            ...vessel,
            id: vessel.id ? vessel.id.toString() : vessel.name,
          })) || [],
      },
    };
    setRequesting(true);
    if (id === "new") {
      const res = await axios.post(`${BASE_URL}/Quotation`, payload);

      if (res.status === 200) {
        toast.success("Quotation created successfully!");
        setQuotation((pre) => ({
          ...pre,
          id: res.data.id,
          quotationNo: res.data.quotationNo,
        }));
        navigate(`/quotation/${res.data.id}`);
      } else {
        toast.error("An error occured while creating Quotation!");
      }
    } else {
      const res = await axios.put(`${BASE_URL}/Quotation`, payload);
      if (res.status === 200) {
        toast.success("Quotation Updated successfully!");

        // configureQuotationData(res.data);

        navigate(`/quotation/${res.data.id}`);

        // document.location.reload();
      } else {
        toast.error("An error occured while updating Quotation!");
      }
    }
    setRequesting(false);
  };

  const handleOnPrint = () => {
    setIsModalOpen(true);
  };
  const handleCompoBoxChange = (event, fieldType) => {
    const { value, name, props } = event.target;
    const isCustom = !value?.id;
    if (isCustom) {
      handleToggle(true);
    }
    if (value) {
      setInvalidFields((prev) => prev.filter((field) => field !== name));
    }
    if (fieldType === "port") {
      setQuotation((prev) => ({
        ...prev,
        [name + "Id"]: value ? value[props.id] : null,
        [name + "Name"]: value ? value?.name : null,
        [name]: value,
        shipment: prev.shipment.map((x) => ({
          ...x,
          options: x.options.map((z) => ({
            ...z,
            [name + "Id"]: value ? value[props.id] : null,
            [name + "Name"]: value ? value?.name : null,
            [name]: value,
            services: z.services.map((y, index) =>
              index === 0
                ? {
                    ...y,
                    [name + "Id"]: value ? value[props.id] : null,
                    [name + "Name"]: value ? value?.name : null,
                    [name]: value,
                  }
                : y
            ),
          })),
        })),
      }));
    } else if (name === "customer") {
      setQuotation((prev) => ({
        ...prev,
        [name + "Id"]: value ? value[props.id] : null,
        [name]: value,
        customerName: isCustom ? value?.name : null,
        cargoDetails: {
          ...prev.cargoDetails,
          cargoes: [],
        },
      }));
    } else
      setQuotation((prev) => ({
        ...prev,
        [name + "Id"]: value ? value[props.id] : null,
        [name]: value,
      }));
  };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    setQuotation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCargoDetailChange = (event, field) => {
    const { value } = event.target;
    setQuotation((prev) => ({
      ...prev,
      cargoDetails: {
        ...prev.cargoDetails,
        [field]: value,
      },
    }));
  };

  const loadCurrencies = async () => {
    const res = await axios.get(`${BASE_URL}/Lookup/Currencies`);
    setCurrencies(res.data);
    setAllCurrencies(res.data);
  };

  const loadVessels = async () => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/Vessels?searchText=~ALL&take=20`
    );
    setVessels(res.data);
  };

  const handleSelectChange = (e) => {
    setQuotation((pre) => ({ ...pre, modes: e.value }));
  };

  const loadPorts = async () => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/Ports?searchText=~ALL&take=10&countryCode=${null}`
    );
    setOriginPorts(res.data);
    setDestinPorts(res.data);
  };

  const closePoModal = () => {
    setModelIsOpen(false);
  };

  const closePrintModal = () => {
    setPrintModelIsOpen(false);
  };

  const loadCustomers = async () => {
    let res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`);
    setCustomers(res.data);
  };

  const loadLogisticServices = async () => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/LogisticsServices?searchText=~ALL`
    );
    setLogisticServices(res.data);
  };

  const handleVesselChange = (e) => {
    setQuotation((prev) => ({
      ...prev,
      cargoDetails: {
        ...prev.cargoDetails,
        vessels: e.value,
      },
    }));
  };

  const handleDateChange = (date, dateString, name) => {
    if (date) {
      // Convert Moment.js object to JavaScript Date
      const selectedDate = date.toDate();
      selectedDate.setHours(12, 0, 0); // Adjust time to noon if needed

      if (name === "quotationDate") {
        const validityDate = new Date(selectedDate);
        validityDate.setDate(selectedDate.getDate() + 2); // Create a new Date for validityDate

        setQuotation((pre) => ({
          ...pre,
          [name]: selectedDate, // Save JavaScript Date object in state
          validityDate, // Save validityDate as a Date object
        }));
      } else {
        setQuotation((pre) => ({
          ...pre,
          [name]: selectedDate, // Save JavaScript Date object in state
        }));
      }
    } else {
      setQuotation((pre) => ({
        ...pre,
        [name]: null, // Handle case when the user clears the input
      }));
    }
  };

  const handleSelectChanges = (event) => {
    setQuotation((prev) => {
      const updatedShipment = event.value.map((mode) => {
        const existingShipment = prev.shipment.find(
          (shipment) => shipment.mode === mode.id
        );
        if (!existingShipment) {
          const origin = {
            id: quotation.originPortId,
            name: quotation.originPortName,
          };
          const destination = {
            id: quotation.destinationPortId,
            name: quotation.destinationPortName,
          };
          return {
            mode: mode.id,
            options: [
              {
                ...defaultOption,
                destinationPort: destination,
                destinationPortId: destination.id,
                originPort: origin,
                originPortId: origin.id,
                services: [
                  {
                    ...defaultService,
                    destinationPort: destination,
                    destinationPortId: destination.id,
                    originPort: origin,
                    originPortId: origin.id,
                  },
                ],
                cargoDetailsOptions: {
                  ...defaultOption.cargoDetailsOptions,
                  quantity: prev.cargoDetails.quantity,
                  length: prev.cargoDetails.length,
                  width: prev.cargoDetails.width,
                  height: prev.cargoDetails.height,
                  grossVolume: prev.cargoDetails.grossVolume,
                  grossWeight: prev.cargoDetails.grossWeight,
                  chargeWeight: prev.cargoDetails.chargeWeight,
                },
              },
            ],
          };
        }
        return existingShipment;
      });

      return { ...prev, modes: event.value, shipment: updatedShipment };
    });
    if (!selectedMode && event.value.length !== 0)
      setSelectedMode(event.value[0].id);
  };

  const saveCargoes = (selectedCargoes) => {
    const uniqueArray = [
      ...new Map(selectedCargoes.map((item) => [item.id, item])).values(),
    ];
    const selectedVessels = [
      ...new Map(selectedCargoes.map((item) => [item.vesselId, item])).values(),
    ];
    setQuotation((pre) => ({
      ...pre,
      cargoDetails: {
        ...pre.cargoDetails,
        vessels: selectedVessels.map((x) => ({
          id: x.vesselId,
          name: x.vesselName,
        })),
        cargoes: selectedCargoes.map((x) => ({
          cargoId: x.id,
          poNo: x.poNo,
          packageId: x.package.id,
          quantity: x.package.colli,
          length: x.package.length,
          width: x.package.width,
          height: x.package.height,
          chargeWeight: x.package.chargableWeight,
          grossVolume: x.package.volume,
          grossWeight: x.package.weight,
        })),
        grossVolume: parseFloat(
          selectedCargoes
            ?.reduce(
              (totalVolume, item) =>
                totalVolume +
                (((item.package.length ?? 0) *
                  (item.package.width ?? 0) *
                  (item.package.height ?? 0)) /
                  1000000) *
                  (item.package.colli ?? 1),
              0
            )
            .toFixed(2)
        ),
        grossWeight: parseFloat(
          selectedCargoes
            .reduce(
              (total, value) =>
                total +
                (value?.package?.weight ?? 0) * (value.package?.colli ?? 1),
              0
            )
            ?.toFixed(2)
        ),
        quantity: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.colli, 0)
            ?.toFixed(2)
        ),
        chargeWeight: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.chargableWeight, 0)
            ?.toFixed(2)
        ),
        length: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.length, 0)
            ?.toFixed(2)
        ),
        height: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.height, 0)
            ?.toFixed(2)
        ),
        width: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.width, 0)
            ?.toFixed(2)
        ),

        posCount: uniqueArray.length,
      },
      shipment: pre.shipment.map((x) => ({
        ...x,
        options: x.options.map((y) => ({
          ...y,
          cargoDetailsOptions: {
            ...y.cargoDetailsOptions,
            grossVolume: parseFloat(
              selectedCargoes
                ?.reduce(
                  (totalVolume, item) =>
                    totalVolume +
                    (((item.package.length ?? 0) *
                      (item.package.width ?? 0) *
                      (item.package.height ?? 0)) /
                      1000000) *
                      (item.package.colli ?? 1),
                  0
                )
                .toFixed(2)
            ),
            grossWeight: parseFloat(
              selectedCargoes
                .reduce(
                  (total, value) =>
                    total +
                    (value?.package?.weight ?? 0) * (value.package.colli ?? 1),
                  0
                )
                ?.toFixed(2)
            ),
            length: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.length, 0)
                ?.toFixed(2)
            ),
            height: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.height, 0)
                ?.toFixed(2)
            ),
            width: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.width, 0)
                ?.toFixed(2)
            ),
            quantity: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.colli, 0)
                ?.toFixed(2)
            ),
            chargeWeight: parseFloat(
              selectedCargoes
                .reduce(
                  (total, value) => total + value.package?.chargableWeight,
                  0
                )
                ?.toFixed(2)
            ),
          },
        })),
      })),
    }));
  };

  const configureQuotationData = (data) => {
    setQuotation({
      ...data,
      quotationDate: data?.quotationDate || new Date(),
      validityDate:
        data?.validityDate || new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
      currency: { code: data.currencyCode, name: data.currencyName },
      customer: { id: data.customerId, name: data.customerName },
      originPort: { id: data.originPortId, name: data.originPortName },
      destinationPort: {
        id: data.destinationPortId,
        name: data.destinationPortName,
      },
      cargoDetails: {
        ...data.cargoDetails,
        vessels: data.cargoDetails?.vessels.map((x) => ({
          ...x,
          id: parseInt(x.id),
        })),
      },
      jobType: jobTypes.find((x) => x.id === data.jobType),
      modes: shipmentModes.filter((x) => data.modes.includes(x.id)),
      shipment: data.shipment.map((x) => ({
        ...x,
        options: x.options.map((y) => ({
          ...y,
          originPort: { id: y.originPortId, name: y.originName },
          destinationPort: { id: y.destinationPortId, name: y.destinationName },

          services: y.services.map((z) => ({
            ...z,
            details: z.details.map((h) => ({ ...h, index: h.id })),
          })),
        })),
      })),
    });
  };

  const loadQuotation = async () => {
    if (id !== "new") {
      setFormLoading(true);
      const res = await axios.get(`${BASE_URL}/Quotation/GetById?id=${id}`);
      let data = res.data;
      setSelectedMode(
        shipmentModes.filter((x) => data.modes.includes(x.id))?.at(0)?.id
      );
      configureQuotationData(data);
      setFormLoading(false);
    }
  };

  useEffect(() => {
    loadCurrencies();
    loadPorts();
    loadCustomers();
    loadLogisticServices();
    loadVessels();
    loadQuotation();
  }, []);

  const renderModesTemplate = (tagData) => {
    return tagData.data.map((x) => (
      <span
        key={x.id}
        className="mr-1 bg-neutral-600 text-white w-auto px-2 text-xs py-1 font-medium flex justify-between items-center rounded h-fit"
      >
        {x.mode}
        {/* <img src={x.icon} className="h-4 px-1" /> */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            const updatedModes = quotation.modes.filter(
              (mode) => mode.id !== x.id
            );
            handleSelectChanges({ value: updatedModes });
          }}
          className="mx-1 text-md text-white hover:text-neutral-200 focus:outline-none"
        >
          &times;
        </button>
      </span>
    ));
  };

  const renderVesselsTemplate = (tagData) => {
    return tagData.data.map((x) => (
      <span
        key={1}
        title={x.id}
        className={`mr-1 text-white w-auto px-2 text-xs py-1 font-medium flex justify-between items-center rounded h-fit ${
          !x.id ? "bg-neutral-600" : "bg-neutral-600"
        }`}
      >
        {!x?.id && (
          <div className="bg-green-600 px-2 text-white h-4 mr-2 rounded-lg">
            new
          </div>
        )}
        {x.name}
        <button
          onClick={(e) => {
            e.stopPropagation();
            const updatedVessels = quotation.cargoDetails.vessels.filter(
              (v) => v.id !== x.id
            );
            handleVesselChange({ value: updatedVessels });
          }}
          className="mx-1 text-md text-white hover:text-neutral-200 focus:outline-none"
        >
          &times;
        </button>
      </span>
    ));
  };
  return (
    <div className="p-8 overflow-y-auto h-[90vh]">
      {formLoading && <KendoLoader />}
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold mb-4">
          {id !== "new" ? (
            <span>
              Edit Quotation :{" "}
              <span className="text-blue-400">{quotation.quotationNo}</span>
            </span>
          ) : (
            "Create New Quotation"
          )}
        </h1>
        <div>
          {id !== "new" ? (
            <button
              // onClick={() => (window.location.href = `/#/quotation/new`)}
              onClick={() => {
                navigate("/quotation/new");
                window.location.reload();
              }}
              className="bg-blue-500 text-white px-4 py-1 rounded-md hover:bg-blue-600 transition"
            >
              New Quotation
            </button>
          ) : (
            // Go Back Button
            <button
              // onClick={() => (window.location.href = `/#/quotations`)}
              onClick={() => {
                navigate("/quotations");
              }}
              className="bg-gray-500 text-white px-4 py-1 rounded-md hover:bg-gray-600 transition"
            >
              Go Back
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="col-span-2 bg-white rounded-md p-4">
          <div className="font-semibold pb-2">Quotation Details</div>
          <div className=" grid grid-cols-3 gap-4 ">
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1 flex justify-between">
                <div>
                  Customer{" "}
                  <span className="text-red-600 text-sm font-medium">*</span>
                </div>
                {!quotation.customer?.id && quotation.customer?.name && (
                  <div className="bg-green-600 px-2 text-white h-4 mr-2 rounded-lg">
                    new
                  </div>
                )}
              </div>
              <ComboBox
                ref={(el) => (inputRefs.current["customer"] = el)}
                className={`customerField w-full h-8 rounded-md text-xs text-neutral-700 ${
                  invalidFields.includes("customer")
                    ? "k-invalid !border-red-500"
                    : ""
                }`}
                data={customers}
                placeholder="Customer"
                name="customer"
                id="id"
                allowCustom
                textField="name"
                onFilterChange={filterList}
                filterable
                value={quotation.customer}
                onChange={(e) => handleCompoBoxChange(e)}
              />
            </div>
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Origin{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <ComboBox
                ref={(el) => (inputRefs.current["originPort"] = el)}
                className={`w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700 ${
                  invalidFields.includes("originPort")
                    ? "k-invalid !border-red-500"
                    : ""
                }`}
                data={originPorts}
                textField="name"
                name="originPort"
                filterable
                id="code"
                onFilterChange={filterList}
                placeholder="Origin"
                value={quotation.originPort}
                onChange={(e) => handleCompoBoxChange(e, "port")}
              />
            </div>
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Destination{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <ComboBox
                ref={(el) => (inputRefs.current["destinationPort"] = el)}
                className={`w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700 ${
                  invalidFields.includes("destinationPort")
                    ? "k-invalid !border-red-500"
                    : ""
                }`}
                data={destinPorts}
                id="code"
                textField="name"
                placeholder="Destination"
                filterable
                name="destinationPort"
                onFilterChange={filterList}
                value={quotation.destinationPort}
                onChange={(e) => handleCompoBoxChange(e, "port")}
              />
            </div>
            <div
              className=" bg-white col-span-3 MultiSelectInlineSroll"
              id="poCompo"
            >
              <div className="text-xs pb-1">
                Mode <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <MultiSelect
                ref={(el) => (inputRefs.current["modes"] = el)}
                className={`w-full h-8 overflow-auto flex items-center rounded-md border border-zinc-300 text-xxs text-neutral-700 ${
                  invalidFields.includes("modes")
                    ? "k-invalid !border-red-500"
                    : ""
                }`}
                data={shipmentModes}
                textField="mode"
                dataItemKey="id"
                value={quotation.modes}
                tagRender={renderModesTemplate}
                placeholder="Mode Type"
                onChange={handleSelectChanges}
              />
            </div>
            <div className=" bg-white " id="poCompo">
              <div className="text-xs pb-1">
                Currency{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <ComboBox
                ref={(el) => (inputRefs.current["currency"] = el)}
                data={currencies}
                name="currency"
                filterable
                textField="code"
                className={`w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700" ${
                  invalidFields.includes("currency")
                    ? "k-invalid !border-red-500"
                    : ""
                }`}
                dataItemKey="code"
                id="code"
                suggest={true}
                clearButton={true}
                onFilterChange={filterList}
                placeholder="Currency"
                onChange={(e) => handleCompoBoxChange(e)}
                value={quotation.currency}
              />
            </div>
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Quotation Date{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <DatePicker
                name="expectedDate"
                className="h-8 w-full py-2 px-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Quotation Date"
                format="DD-MM-YYYY" // Specify your desired format
                value={
                  quotation.quotationDate
                    ? dayjs(quotation.quotationDate)
                    : null
                }
                required={false}
                onChange={(date, dateString) =>
                  handleDateChange(date, dateString, "quotationDate")
                }
              />
            </div>{" "}
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Validity Date{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <DatePicker
                name="expectedDate"
                className="h-8 w-full py-2 px-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Validity Date"
                format="DD-MM-YYYY" // Specify your desired format
                value={
                  quotation.validityDate ? dayjs(quotation.validityDate) : null
                }
                required={false}
                onChange={(date, dateString) =>
                  handleDateChange(date, dateString, "validityDate")
                }
              />
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md p-4">
          {/* Heading with Toggle Button */}
          <div className="font-semibold pb-2 flex justify-between items-center">
            Cargo Details
            <div className="flex gap-2 items-center">
              {/* Toggle Button */}
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-700">
                  {isManualMode ? "Manual Mode" : "Import PO"}
                </span>
                <label className="relative inline-flex items-center cursor-pointer ">
                  <input
                    type="checkbox"
                    checked={isManualMode}
                    disabled={!quotation?.customerId}
                    onChange={(e) => handleToggle(e.target.checked)}
                    className="sr-only peer disabled:cursor-not-allowed"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 rounded-full peer peer-checked:bg-blue-500 peer-checked:after:translate-x-5 peer-checked:after:bg-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                </label>
              </div>
            </div>
          </div>

          {/* Cargo Details Form */}
          <div className="grid grid-cols-2 gap-4">
            <div className="grid grid-cols-2 gap-4 mt-1 col-span-2">
              <div className="mt-1 col-span-1">
                <div className="text-xs pb-1">
                  Pieces/ Packages{" "}
                  <span className="text-red-600 text-sm font-medium">*</span>
                </div>
                <input
                  className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  type="number"
                  placeholder="Quantity"
                  value={quotation.cargoDetails.quantity}
                  onChange={(e) => {
                    handleCargoDetailChange(e, "quantity");
                    // calculateVolumeAndChargeableWeight();
                  }}
                />
              </div>
              <div className="mt-1 col-span-1 flex justify-end items-center gap-2">
                {isManualMode
                  ? quotation?.cargoDetails?.packages?.length > 0 &&
                    `${quotation.cargoDetails.packages.length}`
                  : quotation?.cargoDetails?.cargoes?.length > 0 &&
                    `${quotation.cargoDetails.cargoes.length}`}

                <button
                  disabled={!quotation.customer}
                  title="Import PO"
                  className="flex items-center gap-1 p-1.5 bg-blue-500 hover:bg-blue-600 text-white text-sm rounded-md disabled:opacity-50"
                  onClick={() => {
                    isManualMode
                      ? setManualModeModalOpen(true)
                      : setModelIsOpen(true);
                  }}
                >
                  <img src="import.svg" alt="Import" className="h-4 w-4" />
                  <span>{isManualMode ? "Add Packages" : "Import"}</span>
                </button>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 mt-1 col-span-2">
              {/* Chg Weight (Kg) */}
              <div>
                <div className="text-xs pb-1">
                  Chg Weight (Kg){" "}
                  <span className="text-red-600 text-sm font-medium">*</span>
                </div>
                <input
                  className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  type="number"
                  placeholder="Charge Weight"
                  // value={(
                  //   ((quotation.cargoDetails.length *
                  //     quotation.cargoDetails.width *
                  //     quotation.cargoDetails.height) /
                  //     6000) *
                  //   quotation.cargoDetails.quantity
                  // ).toPrecision(2)}
                  // readOnly
                  value={quotation.cargoDetails.chargeWeight.toFixed(2)}
                  readOnly
                />
              </div>

              {/* Gross Weight (Kg) */}
              <div>
                <div className="text-xs pb-1">
                  Gross Weight (Kg){" "}
                  <span className="text-red-600 text-sm font-medium">*</span>
                </div>
                <input
                  className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  type="number"
                  placeholder="Gross Weight"
                  value={quotation?.cargoDetails?.grossWeight}
                  onChange={(e) => handleCargoDetailChange(e, "grossWeight")}
                  readOnly
                />
              </div>

              {/* Volume (m³) */}
              <div>
                <div className="text-xs pb-1">
                  Volume (m³){" "}
                  <span className="text-red-600 text-sm font-medium">*</span>
                </div>
                <input
                  className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  type="number"
                  placeholder="Cargo Volume"
                  // value={(
                  //   ((quotation.cargoDetails.length *
                  //     quotation.cargoDetails.width *
                  //     quotation.cargoDetails.height) /
                  //     1000000) *
                  //   quotation.cargoDetails.quantity
                  // ).toPrecision(2)}
                  // readOnly

                  value={quotation.cargoDetails?.grossVolume}
                  readOnly
                />
              </div>
            </div>

            <div
              className="bg-white col-span-2 MultiSelectInlineSroll"
              id="poCompo"
            >
              <div className="text-xs pb-1">
                Vessel{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <MultiSelect
                className={`w-full h-8 overflow-auto flex items-center rounded-md border border-zinc-300 text-xxs text-neutral-700 ${
                  invalidFields.includes("vessel")
                    ? "k-invalid !border-red-500"
                    : ""
                }`}
                data={vessels}
                ref={(el) => (inputRefs.current["vessel"] = el)}
                textField="name"
                dataItemKey="id"
                filterable
                name="vessel"
                allowCustom
                onFilterChange={filterList}
                value={quotation?.cargoDetails?.vessels}
                tagRender={renderVesselsTemplate}
                placeholder="Select Vessels"
                onChange={handleVesselChange}
              />
            </div>
          </div>
        </div>
      </div>
      {quotation.shipment?.length > 0 && (
        <div className="bg-white rounded-md p-4">
          <div className="mb-6 ">
            <div className="flex gap-0 mb-6 w-full flex-col">
              <div className="flex w-fit">
                {quotation.modes.map(
                  (mode) => (
                    // quotation.modes.map((x) => x.id).includes(mode.id) && (
                    <div
                      key={mode.id}
                      className={`w-56 h-14 relative p-6 flex flex-col shadow-lg items-center justify-center flex-1 text-center rounded-t-lg cursor-pointer bg-gradient-to-r  ${
                        selectedMode === mode.id
                          ? "bg-[#4c6df1] border text-white"
                          : "text-gray-700  grayscale border border-b-0 border-neutral-300"
                      }`}
                      onClick={() => setSelectedMode(mode.id)}
                    >
                      <div className="flex items-center gap-3">
                        <div className="mx-auto mb-2">
                          <img src={mode.icon} className="h-9" />
                        </div>
                        <h2 className="text-sm  font-semibold">{mode.mode}</h2>
                      </div>
                    </div>
                  )

                  // )
                )}
              </div>
              {isLoading && <KendoLoader />}
              <span className="border-t-4 border-blue-500 flex w-full" />
            </div>
          </div>

          {selectedMode &&
            quotation.shipment.map(
              (i, index) =>
                i.mode === selectedMode && (
                  <ShipmentDetails
                    key={index}
                    setQuotation={setQuotation}
                    quotation={quotation}
                    mode={i.mode}
                    shipment={i}
                    logisticServices={logisticServices}
                    destinationPorts={destinPorts}
                    originPorts={originPorts}
                    defaultOption={defaultOption}
                    defaultService={defaultService}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    filterList={filterList}
                    ref={(el) => (inputRefs.current["service"] = el)}
                    isInvalid={InvalidRef.current.includes("service")}
                  />
                )
            )}
        </div>
      )}
      <div className="bg-white p-4 mt-3 rounded mb-4">
        <div className="font-semibold px-3 pb-2">Comments</div>
        <textarea
          className="w-full outline-none border-0 rounded"
          placeholder="Some notes about the Quotation"
          value={quotation?.comments}
          onChange={(e) => handleInputChange(e, "comments")}
        ></textarea>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => setPrintModelIsOpen(true)}
          className="flex justify-center items-center disabled:opacity-40 disabled:cursor-not-allowed py-1 bg-blue-500 w-20 text-white border rounded-md"
          //   ${
          //   isSaved ? "bg-blue-500" : "bg-blue-500 cursor-not-allowed"
          // } w-20 text-white border rounded-md`}
          disabled={id === "new"}
        >
          Print
        </button>

        <button
          className="flex justify-center items-center py-1 bg-red-600 w-20 text-white border rounded-md"
          onClick={handleOnCancel}
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          disabled={requesting}
          className="px-4 py-2 rounded-md font-semibold flex items-center gap-1 disabled:opacity-30 bg-green-500 text-white cursor-pointer"
          //    ${isFormValid && pendingSelection ? "bg-green-500 text-white cursor-pointer" : "bg-green-500 text-white cursor-not-allowed"}
          // `}
        >
          {requesting && (
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Save
        </button>
        <button
          className={`flex justify-center items-center py-1 bg-blue-600 w-20 text-white border rounded-md ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          // onClick={reactToPrintFn}
          onClick={handleOnPrint}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Send"}
        </button>
        <ReactModal
          isOpen={isModalOpen}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => setIsModalOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
          overlayClassName="OverlayBlack"
          ariaHideApp={false}
        >
          <SendMailModal
            isModalOpen={isModalOpen}
            setModalOpen={setIsModalOpen}
            loadQuotation={loadQuotation}
            formLoader={formLoading}
            setFormLoader={setFormLoading}
            quotation={quotation}
            quotationDocument={quotationPdf}
          />
        </ReactModal>
      </div>

      {/* <SendMailModal isModalOpen={isModalOpen} setModalOpen={setIsModalOpen} quotation={quotation} /> */}
      <ReactModal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={closePoModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="OverlayBlack"
        ariaHideApp={false}
      >
        <CargoImportPopup
          quotation={quotation}
          closeModal={closePoModal}
          onSavePo={saveCargoes}
        />
      </ReactModal>
      <ReactModal
        isOpen={printModalIsOpen}
        //onAfterOpen={afterOpenModal}
        // onRequestClose={closePrintModal}
        style={customStyles2}
        contentLabel="Example Modal"
        overlayClassName="OverlayWhite"
        ariaHideApp={false}
      >
        <QuotationDocument
          quotation={quotation}
          loadQuotation={loadQuotation}
          loader={formLoading}
          closeModal={closePrintModal}
          onSavePo={saveCargoes}
        />
      </ReactModal>

      {/* Manual Mode Modal */}
      <ManualModePopup
        quotation={quotation}
        setQuotation={setQuotation}
        isOpen={manualModeModalOpen}
        onClose={() => setManualModeModalOpen(false)}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default CreateQuotation;
