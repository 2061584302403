import {
  ChevronDownIcon,
  ChevronRightIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import React, { forwardRef, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { formatDate } from "@telerik/kendo-intl";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import EditableKendoTable from "./EditableKendoTable";

const LocationDetails = forwardRef(
  (
    {
      isApproved,
      index,
      expandToggle,
      location,
      expandedLocations,
      quotation,
      serviceTemplate,
      setQuotation,
      logisticServices,
      mode,
      selectedOption,
      removeLocation,
      originPorts,
      destinationPorts,
      isInvalid,
    },
    ref
  ) => {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
      },
    };

    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingSelection, setPendingSelection] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleOk = (serviceId) => {
      setIsModalOpen(false);
      setSelectedItem(pendingSelection);
      saveTemplateServices(pendingSelection, serviceId);
      setPendingSelection(null);
    };

    const save = (updatedData, locationId) => {
      try {
        UpdateLocationData("details", updatedData);
      } catch (errInfo) {
        console.error("Validate Failed:", errInfo);
      }
    };
    const findSelectedPort = (ports, portId) => {
      if (!ports) return null;
      return ports.find((port) => port.code === portId);
    };
    const onServiceExpectedChange = (loc, date) => {
      const selectedDate = new Date(date); // Parse the date string into a JavaScript Date
      selectedDate.setHours(12, 0, 0); // Adjust the time to noon if needed
      UpdateLocationData("expectedDate", selectedDate);

      setOpenDatePicker(false);
    };

    const onTitleChange = (e) => {
      UpdateLocationData("title", e.target.value);
      setOpenDatePicker(false);
    };

    const handleComment = (comment) => {
      UpdateLocationData("comment", comment);
    };
    const handleSourceChange = (event) => {
      handleLocationChange("origin", event);
    };

    const handleDestinationChange = (event) => {
      handleLocationChange("destination", event);
    };
    const handleLocationChange = (type, event) => {
      const value = event.target.value || null;
      const fieldMappings = {
        origin: {
          portId: "originPortId",
          name: "originName",
          port: "originPort",
        },
        destination: {
          portId: "destinationPortId",
          name: "destinationName",
          port: "destinationPort",
        },
      };

      const fields = fieldMappings[type];

      setQuotation((prevQuotation) => {
        const updatedShipment = prevQuotation.shipment.map((shipment) => {
          if (shipment.mode !== mode) return shipment;

          const updatedOptions = shipment.options.map((option) => {
            if (option.id !== selectedOption.id) return option;

            const updatedServices = option.services.map((loc) => {
              if (loc.id !== location.id) return loc;

              return {
                ...loc,
                [fields.portId]: value?.code || null,
                [fields.name]: value?.name || null,
                [fields.port]: value,
              };
            });

            return {
              ...option,
              services: updatedServices,
            };
          });

          return {
            ...shipment,
            options: updatedOptions,
          };
        });

        return {
          ...prevQuotation,
          shipment: updatedShipment,
        };
      });
    };

    const UpdateLocationData = (field, value) => {
      setQuotation((prevQuotation) => ({
        ...prevQuotation,
        shipment: prevQuotation.shipment.map((shipment) =>
          shipment.mode === mode
            ? {
                ...shipment,
                options: shipment.options.map((option) =>
                  option.id === selectedOption.id
                    ? {
                        ...option,
                        services: option.services.map((loc) =>
                          loc.id === location.id
                            ? { ...loc, [field]: value }
                            : loc
                        ),
                      }
                    : option
                ),
              }
            : shipment
        ),
      }));
    };

    const saveTemplateServices = async (data, serviceId) => {
      try {
        const services = data.logisticsServices || [];
        const serviceData = services.map((service) => ({
          index: service.id.toString(),
          serviceId: service.id,
          service: service.name || "",
          description: service.description || "",
          price: service.price || 0,
          quantity: service.quantity || 1,
          amount: (service.price || 0) * (service.quantity || 1),
        }));
        UpdateLocationData("details", serviceData);
      } catch (errInfo) {
        console.error("Error saving template services:", errInfo);
      }
    };

    const handleServiceChange = (event) => {
      setPendingSelection(event.target.value);
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
      setPendingSelection(null);
    };

    return (
      <div key={index} className="flex gap-2">
        <div className="flex flex-col justify-center items-center gap-2">
          <LocationMarkerIcon className="ml-auto flex-shrink-0 h-5 w-5 text-blue-500" />

          <div className="h-full border-l-2 border-dashed" />
        </div>
        <div key={index} className="mb-6 w-full">
          <div className="flex justify-between gap-3 mb-2">
            <div className="flex items-center gap-3 mb-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeLocation(location.id);
                }}
                className="ml-2 text-xl text-red-500 hover:text-red-700 hover:scale-105 "
              >
                &times;
              </button>{" "}
              <div onClick={() => expandToggle(location.id)}>
                {expandedLocations.includes(location.id) ? (
                  <ChevronDownIcon className="ml-auto flex-shrink-0 h-5 w-5 text-blue-500" />
                ) : (
                  <ChevronRightIcon className="ml-auto flex-shrink-0 h-5 w-5 text-blue-500" />
                )}
              </div>
              <div className="flex flex-col gap-1 w-full">
                <div className="flex items-center gap-4">
                  <div className="flex flex-col w-64">
                    <label className="text-sm font-medium text-gray-700 mb-1">
                      Title
                    </label>
                    <input
                      type="text"
                      className="w-full h-8 focus:outline-none focus:ring-0 border-neutral-300 rounded"
                      placeholder="Enter title"
                      disabled={isApproved}
                      onChange={onTitleChange}
                      value={location?.title || ""}
                    />
                  </div>
                  {index === 0 ? (
                    ""
                  ) : (
                    <>
                      <div className="flex flex-col w-64" id="poCompo">
                        <label className="text-sm font-medium text-gray-700 mb-1">
                          Origin
                        </label>
                        <ComboBox
                          className="w-full h-8 rounded-md bg-white border-zinc-300 text-xs text-neutral-700"
                          data={originPorts}
                          disabled={isApproved}
                          placeholder="Select origin"
                          name="originPort"
                          id="id"
                          textField="name"
                          value={findSelectedPort(
                            originPorts,
                            location?.originPortId
                          )}
                          onChange={handleSourceChange}
                        />
                      </div>
                      <div className="flex flex-col w-64" id="poCompo">
                        <label className="text-sm font-medium text-gray-700 mb-1">
                          Destination
                        </label>
                        <ComboBox
                          className="w-full h-8 rounded-md bg-white border-zinc-300 text-xs text-neutral-700"
                          data={destinationPorts}
                          placeholder="Select destination"
                          name="destinationPort"
                          disabled={isApproved}
                          id="id"
                          textField="name"
                          value={findSelectedPort(
                            destinationPorts,
                            location?.destinationPortId
                          )}
                          onChange={handleDestinationChange}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {expandedLocations.includes(location.id) && (
              <div
                className="mt-1 mr-2 bg-white flex items-center gap-3 "
                id="poCompo"
              >
                <ComboBox
                  ref={ref}
                  data={serviceTemplate}
                  name="service"
                  textField="name"
                  disabled={isApproved}
                  className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  dataItemKey="code"
                  suggest={true}
                  clearButton={true}
                  placeholder="Import Tariff"
                  onChange={(event) => handleServiceChange(event)}
                  value={pendingSelection}
                />
                <ReactModal
                  isOpen={isModalOpen}
                  //onAfterOpen={afterOpenModal}
                  onRequestClose={() => setIsModalOpen(false)}
                  style={customStyles}
                  contentLabel="Example Modal"
                  overlayClassName="OverlayBlack"
                  ariaHideApp={false}
                >
                  <div className="flex flex-col gap-y-5">
                    <p>
                      This will delete the existing services. Do you want to
                      continue?
                    </p>
                    <div className="flex justify-end space-x-4">
                      <button
                        onClick={handleCancel}
                        className="flex justify-center items-center py-1 bg-black w-20 text-white border rounded-md"
                      >
                        No
                      </button>
                      <button
                        onClick={() => handleOk(location.id)}
                        className="flex justify-center items-center py-1 bg-green-500 w-20 text-white rounded-md"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </ReactModal>
                {/* <button
                      onClick={() => addNewRow(location.id)}
                      className="p-2  border !text-white rounded-full text-sm"
                    >
                      <img
                        src="plus.svg"
                        className="w-6 cursor-pointer text-white "
                      />
                    </button> */}
              </div>
            )}
          </div>
          {expandedLocations.includes(location.id) && (
            <div key={location.id} className="expanded-content">
              <EditableKendoTable
                isApproved={isApproved}
                services={logisticServices}
                data={location?.details || []}
                locationId={location?.id}
                save={save}
              />

              <div className="flex flex-row items-center justify-between mt-4 pr-5">
                <div className="bg-white p-4 mt-1 w-1/2 rounded mb-4">
                  <div className="font-semibold px-1 pb-2">Comments</div>
                  <textarea
                    className="w-full focus:outline-none disabled:opacity-40 focus:ring-0 border-neutral-300 rounded"
                    placeholder="Some notes about the option"
                    rows={2}
                    disabled={isApproved}
                    onChange={(e) => handleComment(e.target.value, location)}
                    value={location?.comment || ""}
                  ></textarea>
                </div>
                <div className="">
                  <p className="text-xl font-semibold">
                    <span className="text-base p-2 text-neutral-600">
                      Total :
                    </span>
                    {quotation?.currency?.code}{" "}
                    {parseFloat(
                      location?.details
                        .reduce((a, b) => a + b.price * b.quantity, 0)
                        .toFixed(2)
                    ).toFixed(2) || 0}
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default LocationDetails;
