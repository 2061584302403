import React, { useContext, useState } from "react";
import AppContext from "../../../context/app-context";
import TandC from "./TandC";

const Hbl = ({ job, poTotal, handleChange, handleAreaChange }) => {
  const [isEditable, setIsEditable] = useState(true);
  console.log(job)
  return (
    <div>
      <div class="flex flex-col w-full justify-center" id="print_div">
        <div class="border-2 border-black w-[700px] min-h-[950px] portrait">
          <div class="flex justify-end border-b border-black h-6 item-center pt-1">
            {job?.blType === 100 && (
              <div class="w-20 text-xs">
                <label class="font-bold">B/L NO:</label>
              </div>
            )}
            <div class="text-xs w-32">
              {job?.blType === 100 ? job?.blNumber : "Telex Release"}
            </div>
          </div>
          <div class="flex border-black">
            <div class="text-xs w-1/2 pl-1 border-b border-r border-black">
              Shipper
              <br />
              <input
                class="font-semibold w-full bg-inherit editable"
                name="shipperName"
                onChange={handleChange}
                value={job?.shipperName}
              />
              <br />
              <textarea
                class="font-semibold resize-none overflow-clip border-0 focus:ring-0 p-0 text-xs w-full bg-inherit editable"
                name="shipperAddress"
                rows={8}
                onChange={(e) => handleAreaChange(e, 8)}
                value={job?.shipperAddress}
              />
              {/* <span class="font-bold editable" contentEditable={isEditable}>
                {job?.shipperName}
                <br />
                {job?.shipperAddress}
              </span> */}
            </div>
            <div class="flex border-black flex-col w-1/2 pr-1">
              <div class="flex font-bold text-xs justify-end">
                NON NEGOTIABLE
              </div>
              <div>
                <h1 class="flex font-bold px-8 py-3 justify-center text-center">
                  COMBINED TRANSPORT BILL OF LADING
                </h1>
              </div>
              <div class="flex justify-center">
              <img src="logo.png" alt="voyagelogo"  className="h-20"/>
              </div>
            </div>
          </div>
          <div class="flex border-b">
            <div class="flex justify-between h-full flex-col w-1/2 border-r border-black">
              <div class="border-b border-black text-xs h-44 pl-1">
                Consignee or To Order
                <br />
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="consigneeName"
                  onChange={handleChange}
                  value={job?.consigneeName}
                />
                <br />
                <textarea
                  class="font-semibold resize-none overflow-clip border-0 focus:ring-0 p-0 text-xs w-full bg-inherit editable"
                  name="consigneeAddress"
                  rows={8}
                  onChange={(e) => handleAreaChange(e, 8)}
                  value={job?.consigneeAddress}
                />
                {/* <span class="font-bold editable" contentEditable={isEditable}>
                  {job?.consigneeName}
                  <br />
                  {job?.consigneeAddress}
                </span> */}
              </div>
              <div class="flex flex-col text-xs pl-1">
                <div class="flex jusify-start h-1/4">Notify Party</div>
                <div
                  class="flex font-bold jusify-start h-1/2 editable"
                  // contentEditable={isEditable}
                >
                  <textarea
                    class="font-semibold resize-none overflow-clip border-0 focus:ring-0 p-0 text-xs w-full bg-inherit editable"
                    name="partyAddress"
                    rows={8}
                    onChange={(e) => handleAreaChange(e, 8)}
                    value={job?.notifyPartyAddress}
                  />
                  {/* SAME AS CONSIGNEE */}
                </div>
              </div>
            </div>
            <div class=" border-black h-full w-1/2 px-1">
              <h1 class="flex justify-center">
                VOYAGE MARINE LOGISTICS PTE LTD
              </h1>
              <span class="flex justify-center text-sm text-center">
                190 MIDDLE ROAD, #13-01 FORTUNE CENTRE
                <br />
                SINGAPORE 188979
              </span>
              <span class="flex text-xxxs h-full mt-1 text-justify">
                RECEIVED by the Carrier the Goods as specified below in apparent
                good order and condition unless otherwise stated, to be
                transported to such place as agreed, authorised or permitted
                herein and subject to all the terms and conditions appearing on
                the front and reverse of this Bill of Lading to which the
                Merchant agrees by accepting this Bill of Lading any local
                privileges and customs notwithstanding. <br />
                The Particulars given below as stated by the shipper and the
                weight, measure, quantity, condition, contents and value of the
                Goods are unknown to the Carrier.
                <br />
                LAW AND JURISDICTION CLAUSE <br />
                The Contract evidenced by or contained in this Bill of Lading is
                governed by the law of Singapore and any claim or dispute
                arising thereunder or in connection herewith shall (without
                prejudice to the Carrier's right to commence proceeding in any
                other urisdiction) be subject to the jurisdiction to the Courts
                of Singapore.
                <br />
                In WITNESS whereof one (1) original Bill of Lading has been
                signed if not otherwise stated above, the same being
                accomplished the other(s), if any, to be void. If required by
                the Carrier one (1) original Bill of Lading must be surrendered
                duly endorsed in exchange for the Goods or delivery order.
              </span>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex border-t border-black w-full text-xs pl-1 pr-1">
              <div class="w-1/4">
                <label>Place Of Receipt</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="originPortName"
                  onChange={handleChange}
                  value={job?.originPortName}
                />
                {/* <span
                  class="flex font-bold editable"
                  contentEditable={isEditable}
                >
                  {" "}
                  {job?.destination}
                </span> */}
              </div>
              <div class="w-1/4">
                <label>Port of Discharge</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="destinationPortName"
                  onChange={handleChange}
                  value={job?.destinationPortName}
                />
                {/* <span
                  class="flex font-bold editable"
                  contentEditable={isEditable}
                >
                  {" "}
                  {job?.destinationPortName}{" "}
                </span> */}
              </div>
              <div class="w-1/4">
                <label>Vessel/Voyage</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="seaVessel"
                  onChange={handleChange}
                  value={job?.seaVessel || job?.voyage}
                />
                {/* <span
                  class="flex font-bold editable"
                  contentEditable={isEditable}
                >
                  {job?.seaVessel || job?.voyage}
                </span> */}
              </div>
              <div class="w-1/4">
                <label className="">No. Of Original Bill of Ladings</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="noOfBlsLading"
                  onChange={handleChange}
                  value={job?.noOfBlsLading}
                />
                {/* <span
                  class="flex font-bold justify-center editable"
                  contentEditable={isEditable}
                ></span> */}
              </div>
            </div>
            <div class="flex border-t border-black w-full text-xs pl-1 pr-1">
              <div class="w-1/4">
                <label>Port Of Loading</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="originPortName"
                  onChange={handleChange}
                  value={job?.originPortName}
                />
                {/* <span
                  class="flex font-bold editable"
                  contentEditable={isEditable}
                >
                  {" "}
                  {job?.originPortName}{" "}
                </span> */}
              </div>
              <div class="w-1/4">
                <label>Final Destination</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="destinationPortName"
                  onChange={handleChange}
                  value={job?.destinationPortName}
                />
                {/* <span
                  class="flex font-bold editable"
                  contentEditable={isEditable}
                >
                  {" "}
                  {job?.destination}{" "}
                </span> */}
              </div>
              <div class="w-1/4">
                <label>Freight Payable At</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="freightPayable"
                  onChange={handleChange}
                  value={job?.freightPayable}
                />
                {/* <span
                  class="flex font-bold editable"
                  contentEditable={isEditable}
                >
                  {" "}
                </span> */}
              </div>
              <div class="w-1/4">
                <label>Freight & Charges</label>
                <input
                  class="font-semibold w-full bg-inherit editable"
                  name="freightAndCharges"
                  onChange={handleChange}
                  value={job?.freightAndCharges}
                />
                {/* <span
                  class="flex font-bold editable"
                  contentEditable={isEditable}
                >
                </span> */}
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col border-t border-black text-xs">
              <div class="flex text-xs">
                <div class="w-1/4 flex flex-col">
                  <div>
                    <label class="flex border-r border-b border-black px-1 h-9">
                      Marks & Nos.
                    </label>
                  </div>
                  <textarea
                    class="font-semibold p-1 resize-none overflow-clip border-0 focus:ring-0 text-xs w-full bg-inherit editable"
                    name="Marks"
                    rows={11}
                    onChange={(e) => handleAreaChange(e, 11)}
                    value={job?.Marks}
                  />
                  {/* <div class="font-bold editable" contentEditable={isEditable}>
                    SGA6S
                    <br />
                    PRESIDENT FD ROOSEVELT
                    <br />
                    SHIP SPARES IN TRANSIT
                    <br />
                    C/O Logfret Korea LTD
                    <br />
                    Busan Port Intl Ship
                    <br />
                    Supply Center 3F-#24
                    <br />
                    #408, 85 Namhangseo-ro
                    <br />
                    Yeongdo-Gu, Busan 49049, S.KOREA
                    <br />
                    Tel: +82 51 442 1207
                  </div> */}
                </div>
                <div class="w-1/2 flex flex-col">
                  <div className="border-b border-black border-r h-9">
                    <label class="flex pl-6">
                      Quantity & Description of Goods
                    </label>
                    <span class="flex pl-4">
                      Shipper's Load & Count, Said to Contain:
                    </span>
                  </div>
                  <div
                    className="font-bold flex flex-col gap-3 pt-3 editable"
                    contentEditable={isEditable}
                  >
                    <div className="flex gap-1">
                      <input
                        class="font-semibold w-5 bg-inherit editable"
                        name="palletsNo"
                        onChange={handleChange}
                        value={job?.palletsNo}
                      />
                      PALLET
                    </div>
                    <div>
                      <br />
                      SHIP SPARES PARTS <br />
                    </div>

                    <div>SHIP SPARES IN TRANSIT FOR PRESIDENT FD ROOSEVEL</div>
                    <div>"EXPRESS RELEASE"</div>
                    <div className=" font-normal">SHIPPED ON BOARD:</div>
                  </div>
                </div>
                <div class="w-1/6 flex flex-col">
                  <div>
                    <label class="flex border-r border-b text-center border-black px-1 h-9">
                      Gross Weight, Kg
                    </label>
                  </div>
                  <span
                    class="flex justify-center pt-10 font-bold editable"
                    contentEditable={isEditable}
                  >
                    {poTotal?.totalChWeight}
                    &nbsp;KGS
                  </span>
                </div>
                <div class="w-1/6 flex flex-col">
                  <label class="flex border-b text-center border-black px-1 h-9">
                    Measurement, m3
                  </label>
                  <span
                    class="flex justify-center pt-10 font-bold editable"
                    contentEditable={isEditable}
                  >
                    {poTotal?.totalVolume}
                    &nbsp; M3
                  </span>
                </div>
              </div>
              <div class="flex text-xs">
                <div class="w-1/4"></div>
                <div class="w-1/2">
                  <span class="flex"> </span>
                </div>
                <div class="w-1/4"></div>
                <div class="w-1/4"></div>
              </div>
              <div class="flex text-xs pb-2 pl-1">
                <span class="flex">
                  Container/ Seal Number:
                  <input
                    class="font-semibold w-fit bg-inherit editable"
                    name="containerOrSealNumber"
                    onChange={handleChange}
                    value={job?.containerOrSealNumber}
                  />
                </span>
              </div>
              <div class="flex w-full">
                <div class="flex flex-col border-t border-b border-black w-1/2 pl-1">
                  <span class="flex text-xs underline">
                    For Delivery of Goods, please apply to:
                  </span>
                  <textarea
                    class="font-semibold p-1 resize-none h-full overflow-clip border-0 focus:ring-0 text-xs w-full bg-inherit editable"
                    name="deliveryOfGoods"
                    onChange={handleChange}
                    value={job?.deliveryOfGoods}
                  />
                  {/* <div className="editable" contentEditable={isEditable}></div> */}
                </div>
                <div class="flex flex-col border-l border-t border-b border-black w-1/2">
                  <div class="border-b border-black editable pl-1">
                    <label>Place and Date of Issue:</label>
                    <br />
                    <div className="flex items-center w-fit justify-start">
                      <input
                        class="font-bold editable w-fit border-r-2 border-black border-y-0 border-l-0 my-1"
                        value="SINGAPORE"
                      />
                      <input
                        class="font-normal w-fit bg-inherit px-2 editable"
                        name="issuePlaceDate"
                        onChange={handleChange}
                        value={job?.issuePlaceDate}
                      />
                    </div>
                  </div>
                  <div class="flex flex-col pl-1">
                    <label>Signed for:</label>
                    <span class="flex justify-center mt-1 underline">
                      by: VOYAGE MARINE LOGISTICS PTE LTD
                    </span>
                    <span class="flex font-bold justify-center p-2">
                      As Agents to Carrier Only
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full pl-1 pr-1">
              <span class="w-full text-xs">
                The Merchant's attention is called to the fact that according to
                the terms & conditions of this Bill of Lading, the liability of
                Carrier is, in most cases,limited in respect of loss of or
                damage to the goods and delay.
              </span>
            </div>
          </div>
        </div>
        <div>
          <TandC />
        </div>
      </div>
    </div>
  );
};

export default Hbl;
