import React, { useEffect, useState } from "react";

const ManualModePopup = ({ quotation, isOpen, onClose, setQuotation }) => {
  const [manualPackage, setManualPackage] = useState(
    quotation?.cargoDetails?.packages || [
      {
        title: "",
        quantity: 1,
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
      },
    ]
  );

  useEffect(() => {
    if (quotation?.cargoDetails?.packages) {
      setManualPackage(quotation.cargoDetails.packages);
    }
  }, [quotation]);

  const handleChange = (index, field, value) => {
    const newPackage = [...manualPackage];
    newPackage[index][field] = value !== undefined ? value : "";
    setManualPackage(newPackage);
  };

  const getTotalWeight = () => {
    return manualPackage.reduce(
      (sum, pkg) => sum + pkg.weight * pkg.quantity,
      0
    );
  };

  const removePackage = (index) => {
    const newPackage = manualPackage.filter((_, i) => i !== index);
    setManualPackage(newPackage);
  };

  const addPackage = () => {
    if (manualPackage.length < 6) {
      setManualPackage([
        ...manualPackage,
        {
          title: "",
          quantity: 1,
          weight: 0,
          length: 0,
          width: 0,
          height: 0,
        },
      ]);
    } else {
      alert("You can only add a maximum of 5 rows.");
    }
  };

  const savePackage = () => {
    const updatedQuotation = {
      ...quotation,
    };
    setQuotation((pre) => ({
      ...pre,
      cargoDetails: {
        ...quotation.cargoDetails,
        packages: manualPackage,
        grossWeight: getTotalWeight(),
        quantity: parseFloat(
          manualPackage
            .reduce((total, value) => total + value.quantity, 0)
            ?.toFixed(2)
        ),
        chargeWeight: parseFloat(
          manualPackage
            ?.reduce(
              (totalChargeWeight, item) =>
                totalChargeWeight +
                (((item.length ?? 0) * (item.width ?? 0) * (item.height ?? 0)) /
                  6000) *
                  (item.quantity ?? 1),
              0
            )
            .toFixed(2)
        ),
        length: parseFloat(
          manualPackage
            .reduce((total, value) => total + value.length, 0)
            ?.toFixed(2)
        ),
        height: parseFloat(
          manualPackage
            .reduce((total, value) => total + value.height, 0)
            ?.toFixed(2)
        ),
        width: parseFloat(
          manualPackage
            .reduce((total, value) => total + value.width, 0)
            ?.toFixed(2)
        ),
        grossVolume: parseFloat(
          manualPackage
            ?.reduce(
              (totalVolume, item) =>
                totalVolume +
                (((item.length ?? 0) * (item.width ?? 0) * (item.height ?? 0)) /
                  1000000) *
                  (item.quantity ?? 1),
              0
            )
            .toFixed(2)
        ),
      },
      shipment: pre.shipment.map((x) => ({
        ...x,
        options: x.options.map((y) => ({
          ...y,
          cargoDetailsOptions: {
            ...y.cargoDetailsOptions,
            grossVolume: parseFloat(
              manualPackage
                ?.reduce(
                  (totalVolume, item) =>
                    totalVolume +
                    (((item.length ?? 0) *
                      (item.width ?? 0) *
                      (item.height ?? 0)) /
                      1000000) *
                      (item.quantity ?? 1),
                  0
                )
                .toFixed(2)
            ),
            grossWeight: getTotalWeight(),
            length: parseFloat(
              manualPackage
                .reduce((total, value) => total + value.length, 0)
                ?.toFixed(2)
            ),
            height: parseFloat(
              manualPackage
                .reduce((total, value) => total + value.height, 0)
                ?.toFixed(2)
            ),
            width: parseFloat(
              manualPackage
                .reduce((total, value) => total + value.width, 0)
                ?.toFixed(2)
            ),
            quantity: parseFloat(
              manualPackage
                .reduce((total, value) => total + value.quantity, 0)
                ?.toFixed(2)
            ),
            chargeWeight: parseFloat(
              manualPackage
                ?.reduce(
                  (totalChargeWeight, item) =>
                    totalChargeWeight +
                    (((item.length ?? 0) *
                      (item.width ?? 0) *
                      (item.height ?? 0)) /
                      6000) *
                      (item.quantity ?? 1),
                  0
                )
                .toFixed(2)
            ),
          },
        })),
      })),
    }));
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-70 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold text-gray-900">Add Packages</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 p-4">
          <div className="space-y-4">
            {manualPackage.map((pkg, index) => (
              <div key={index} className="flex gap-3 items-center">
                {/* Title Field */}
                <div className="w-[30%]">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Title
                  </label>
                  <input
                    type="text"
                    value={pkg.title}
                    className="w-full border rounded-md px-3 py-2 text-gray-800 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                    onChange={(e) =>
                      handleChange(index, "title", e.target.value)
                    }
                  />
                </div>

                {/* Quantity Field */}
                <div className="w-20">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Quantity
                  </label>
                  <input
                    type="number"
                    value={pkg.quantity}
                    className="w-full border rounded-md px-3 py-2 text-gray-800 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                    onChange={(e) =>
                      handleChange(index, "quantity", parseInt(e.target.value))
                    }
                    min="1"
                  />
                </div>

                {/* Weight Field */}
                <div className="w-24">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Weight (kg)
                  </label>
                  <input
                    type="number"
                    value={pkg.weight}
                    className="w-full border rounded-md px-3 py-2 text-gray-800 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                    onChange={(e) =>
                      handleChange(index, "weight", parseFloat(e.target.value))
                    }
                    min="0"
                  />
                </div>

                {/* Dimensions Fields: Length × Width × Height */}
                <div className="flex gap-2 items-end">
                  <div className="flex flex-col w-24">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Length (cm)
                    </label>
                    <input
                      type="number"
                      value={pkg.length}
                      placeholder="0"
                      className="w-20 border rounded-md px-3 py-2 text-gray-800 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                      onChange={(e) =>
                        handleChange(index, "length", parseInt(e.target.value))
                      }
                      min="0"
                    />
                  </div>
                  <div className="flex flex-col w-24">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Width (cm)
                    </label>
                    <input
                      type="number"
                      value={pkg.width}
                      placeholder="0"
                      className="w-20 border rounded-md px-3 py-2 text-gray-800 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                      onChange={(e) =>
                        handleChange(index, "width", parseInt(e.target.value))
                      }
                      min="0"
                    />
                  </div>
                  <div className="flex flex-col w-24">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Height (cm)
                    </label>
                    <input
                      type="number"
                      value={pkg.height}
                      placeholder="0"
                      className="w-20 border rounded-md px-3 py-2 text-gray-800 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                      onChange={(e) =>
                        handleChange(index, "height", parseInt(e.target.value))
                      }
                      min="0"
                    />
                  </div>
                </div>

                {/* Remove Button */}
                <button
                  onClick={() => removePackage(index)}
                  className="text-red-500 hover:text-red-700 ml-2 p-2 flex items-center"
                >
                  <img src="trash.svg" className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="border-t p-4 bg-gray-50 rounded-b-lg">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div className="flex flex-col sm:flex-row gap-4 text-gray-700">
              <span className="font-medium">
                Total Packages: {manualPackage.length}
              </span>
              <span className="font-medium">
                Total Weight: {getTotalWeight()} KG
              </span>
            </div>
            <div className="flex gap-3">
              <button
                onClick={addPackage}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors w-fit"
              >
                Add Row
              </button>
              <button
                onClick={savePackage}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors w-fit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualModePopup;
