import React from "react";
import JobCard from "../JobCard";

const VmlCargoLabel = ({ po }) => {
  return (
    <div className="flex flex-col gap-20 h-full cargolabel" id="print_div">
      {po &&
        po.packages.map((pckg, index) => {
          const packageContent = Array.from({ length: pckg.colli }).map(
            (_, i) => (
              <div
                class={`border-2 border-black w-[940px] h-[690px] p-10 ${
                  index === 0 ? "mt-5" : "mt-5"
                }`}
                key={i}
              >
                <div class="flex justify-start">
                  <img src="logo.png" alt="voyagelogo" className="h-20" />
                </div>
                <div class="flex flex-col w-full pt-12 pr-6">
                  <div class="flex jusiify-start w-full pl-8">
                    <label class="font-bold text-xl pr-2 w-40">CUSTOMER</label>
                    <div class="text-lg">:&nbsp;{po?.customerName}</div>
                  </div>
                  <div class="flex jusiify-start w-full pl-8">
                    <label class="font-bold text-xl w-40">VESSEL’S NAME</label>
                    <div class="text-xl">:&nbsp;{po?.vesselName}</div>
                  </div>
                  <div class="flex jusiify-start w-full pl-8">
                    <label class="font-bold text-xl w-40">PO/ AWB NO</label>
                    <div class="text-xl w-fit">:&nbsp;{po?.poNo}</div>
                  </div>
                  <div class="flex justify-between pt-5">
                    <div class="flex flex-col gap-2 pl-8">
                      <div class="flex">
                        <label class="font-bold text-xl w-40">PCS</label>
                        <div class="text-xl ">
                          :&nbsp;{i + 1}/{pckg?.colli}
                        </div>
                      </div>
                      <div class="flex">
                        <label class="font-bold text-xl w-40">DIMENSION</label>
                        <div class="text-xl ">
                          :&nbsp;{pckg?.height}X {pckg?.width} X {pckg?.length}
                          &nbsp; CM
                        </div>
                      </div>
                      <div class="flex">
                        <label class="font-bold text-xl w-40">
                          GROSS WEIGHT
                        </label>
                        <div class="text-xl">
                          :&nbsp;{pckg?.weight}&nbsp; KG
                        </div>
                      </div>
                      <div class="flex">
                        <label class="font-bold text-xl w-40">C.WEIGHT</label>
                        <div class="text-xl">
                          :&nbsp;{pckg?.chargableWeight}&nbsp;KG
                        </div>
                      </div>
                      <div class="flex">
                        <label class="font-bold text-xl w-40">VOLUME</label>
                        <div class="text-xl ">:&nbsp;{pckg?.volume}</div>
                      </div>
                    </div>
                    <div class="flex pt-44">
                      <img
                        src="VML_CARGO_LABEL(1)-1 BARCODE.jpg"
                        alt="vml_cargo_barcode(1) w-[214px] h-[412px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          );
          return packageContent;
        })}
    </div>
  );
};

export default VmlCargoLabel;
