import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Editor from "react-simple-wysiwyg";
import { BASE_URL } from "../../constants";
import axios from "axios";
import ReactDOM from "react-dom";
import QuotationDocumentContent from "./QuotationDocumentContent";
import ReactDOMServer from "react-dom/server";
import { useReactToPrint } from "react-to-print";

function SendMailModal({
  isModalOpen,
  setModalOpen,
  quotation,
  quotationDocument,
  loadQuotation,
  formLoader,
  setFormLoader,
}) {
  const componentRef = useRef();
  const [loader, setLoader] = useState(false);

  const [mailData, setMailData] = useState(() => ({
    to: "",
    cc: "",
    subject: quotation?.quotationNo
      ? `Quotation No: ${quotation.quotationNo}`
      : "Quotation",
    body: `Dear Sir/Madam,<br><br>
        Please find attached the quotation for your reference.<br><br>
        Best regards,<br>
        VML <br>`,
    attachments: [],
  }));

  useEffect(() => {
    if (quotation?.quotationNo) {
      setMailData((prevData) => ({
        ...prevData,
        subject: `Quotation No: ${quotation.quotationNo}`,
      }));
    }
  }, [quotation]);

  useEffect(() => {
    loadQuotation();
  }, []);

  useEffect(() => {
    if (isModalOpen && quotationDocument && quotation?.quotationNo) {
      const filename = `Quotation_${quotation.quotationNo}.pdf`;
      const file = new File([quotationDocument], filename, {
        type: "application/pdf",
      });

      setMailData((prevData) => ({
        ...prevData,
        attachments: [
          {
            file,
            name: filename,
            size: file.size,
          },
        ],
      }));
    }
  }, [isModalOpen, quotation, quotationDocument]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setMailData({
      to: "",
      cc: "",
      subject: quotation?.quotationNo
        ? `Quotation: ${quotation.quotationNo}`
        : "Quotation",
      body: `Dear Sir/Madam,<br><br>
            Please find attached the quotation for your reference.<br><br>
            Best regards,<br>
            VML <br>`,
      attachments: [],
    });
  };

  const reactToPrintFn = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe) => {
      await handleSendEmail(printIframe);
    },
  });

  const convertImageToBase64 = async (imgElement) => {
    try {
      const response = await fetch(imgElement.src);
      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error loading image:", error);
      return imgElement.src; // Fallback to original src
    }
  };

  const processImages = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const imgElements = doc.querySelectorAll("img");

    for (let img of imgElements) {
      if (!img.src.startsWith("data:")) {
        // Only convert if not already Base64
        img.src = await convertImageToBase64(img);
      }
    }

    return doc.documentElement.outerHTML;
  };

  const handleSendEmail = async (printIframe) => {
    // e.preventDefault();
    setLoader(true);
    const doc = printIframe.contentDocument?.getElementById("content-print");
    const updatedHtml = await processImages(doc.innerHTML);

    console.log(updatedHtml, "innerhtml");
    try {
      const htmlContent = `<html>
                      <head>
                          <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
                          <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
                      </head>
                      <body class="p-6" style="background-color: #f3f4f6; font-family: 'Poppins', sans-serif;">
                  ${updatedHtml}
              </body>
                  </html>`;

      // Convert HTML content to a Blob and create a File
      const file = new File([htmlContent], "filename.html", {
        type: "text/html",
      });

      // Prepare form data for sending email
      const sendEmailFormData = new FormData();
      sendEmailFormData.append("ToEmail", mailData.to);
      if (mailData.cc.trim()) {
        sendEmailFormData.append("cc", mailData.cc);
      }
      sendEmailFormData.append("subject", mailData.subject);
      sendEmailFormData.append("body", mailData.body);
      sendEmailFormData.append("htmlFile", file, "quotation.html");
      // Send request to send email
      const sendResponse = await axios.post(
        `${BASE_URL}/quotation/send`,
        sendEmailFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (sendResponse.status === 200) {
        toast.success("Email sent successfully!");
        handleCloseModal();
      } else {
        toast.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send email");
    } finally {
      setLoader(false);
    }
  };

  const handlePreviewQuotation = () => {
    const previewWindow = window.open("", "_blank");

    // Get the styles from the current document
    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("\n");
        } catch (e) {
          return "";
        }
      })
      .join("\n");

    // Write the content to the new window with all necessary styles
    previewWindow.document.write(`
            <!DOCTYPE html>
            <html>
                <head>
                    <title>Quotation Preview - ${
                      quotation?.quotationNo || ""
                    }</title>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
                    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
                    <style>
                        ${styles}
                        body {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 100vh;
                        background-color: white;
                    }
                    #preview-content {
                        width: 80%;
                        max-width: 900px;
                        background: white;
                        padding: 20px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        overflow-y: auto;
                        max-height: 90vh;
                    }
                        @media print {
                            body {
                                -webkit-print-color-adjust: exact;
                                print-color-adjust: exact;
                            }
                        }
                    </style>
                </head>
                <body>
                    <div id="preview-content"></div>
                </body>
            </html>
        `);

    // Create a new container for the preview
    const PreviewContainer = () => {
      const previewRef = useRef();

      return (
        <div className="w-full min-h-screen">
          <QuotationDocumentContent
            quotation={quotation}
            componentRef={previewRef}
          />
        </div>
      );
    };

    // Render the component in the new window
    ReactDOM.render(
      <PreviewContainer />,
      previewWindow.document.getElementById("preview-content")
    );

    // Close the preview window when the main window is closed
    window.addEventListener("unload", () => {
      previewWindow.close();
    });
  };

  return (
    <div className="w-full height-full">
      {isModalOpen && (
        <div className=" w-full h-full flex items-center justify-center bg-opacity-50 z-50">
          <div
            className="bg-white p-6 rounded shadow-lg relative w-[60rem] "
            onClick={(e) => e.stopPropagation()}
          >
            <h1 className="text-2xl font-semibold mb-2">Send Quotation</h1>
            {loader ? (
              <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <form
                className="max-h-[70vh] pr-3 overflow-auto"
                onSubmit={reactToPrintFn}
              >
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    To:
                  </label>
                  <input
                    type="email"
                    required
                    value={mailData.to}
                    onChange={(e) =>
                      setMailData({ ...mailData, to: e.target.value })
                    }
                    className="w-full border border-gray-300 rounded p-2"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    CC:
                  </label>
                  <input
                    type="text"
                    value={mailData.cc}
                    onChange={(e) =>
                      setMailData({ ...mailData, cc: e.target.value })
                    }
                    className="w-full border border-gray-300 rounded p-2"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Subject:
                  </label>
                  <input
                    type="text"
                    required
                    value={mailData.subject}
                    onChange={(e) =>
                      setMailData({ ...mailData, subject: e.target.value })
                    }
                    className="w-full border border-gray-300 rounded p-2"
                  />
                </div>
                <div className="mb-4">
                  <Editor
                    value={mailData.body}
                    onChange={(e) =>
                      setMailData({ ...mailData, body: e.target.value })
                    }
                    containerProps={{
                      className: "border border-gray-300 rounded min-h-[320px]",
                    }}
                  />
                </div>

                <div className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-1">
                    Attachments:
                  </h3>
                  <div className="border border-gray-300 p-3 rounded">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <div>
                          <p className="text-sm font-medium">
                            Quotation_{quotation?.quotationNo}.pdf
                          </p>
                          {/* <p className="text-xs text-gray-500">
                            {mailData.attachments[0]?.size
                              ? `${(
                                  mailData.attachments[0].size / 1024
                                ).toFixed(2)} KB`
                              : "Size calculating..."}
                          </p> */}
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={handlePreviewQuotation}
                        disabled={formLoader}
                        className=" flex gap-2 justify-center items-center text-blue-600 hover:text-blue-800 px-3 py-1"
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={loader}
                    className="mt-4 self-end p-2 w-fit bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
                  >
                    {loader ? "Sending..." : "Send Email"}
                  </button>
                </div>
              </form>
            )}
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-4 text-gray-500 hover:text-black"
            >
              ✖
            </button>
          </div>
        </div>
      )}

      <div className="hidden">
        <QuotationDocumentContent
          quotation={quotation}
          componentRef={componentRef}
        />
      </div>
    </div>
  );
}

export default SendMailModal;
