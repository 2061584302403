import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constants";
import KendoLoader from "../KendoLoader";
import { filterBy } from "@progress/kendo-data-query";
import { Navigate, useNavigate } from "react-router-dom";

const ShippingCard = ({
  poNo,
  vesselName,
  warehouseName,
  package: { volume, weight, colli },
}) => {
  return (
    <div className="max-w-md mx-auto text-xs bg-white rounded-lg shadow-sm border overflow-hidden m-1">
      <div className="p-2">
        <div className="flex items-center justify-between mb-1">
          <div className="bg-blue-50 px-2 py-1 rounded">
            <span className="text-blue-600 font-semibold text-xs">PO:</span>
            <span className="ml-1 text-blue-800 font-bold text-sm">{poNo}</span>
          </div>
          <div className="flex space-x-2">
            <div className="bg-green-50 px-2 py-1 rounded">
              <span className="text-green-600 font-semibold text-xs">Vol:</span>
              <span className="ml-1 text-green-800 font-bold text-sm">
                {volume} m³
              </span>
            </div>
            <div className="bg-red-50 px-2 py-1 rounded">
              <span className="text-red-600 font-semibold text-xs">Wt:</span>
              <span className="ml-1 text-red-800 font-bold text-sm">
                {weight} kg
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-5 gap-1 text-xs mt-3">
          <div className="flex items-center col-span-3">
            <span className="text-gray-600 font-medium">Vessel:</span>
            <span className="ml-1 text-gray-800">{vesselName}</span>
          </div>

          <div className="flex items-center justify-end col-span-2">
            <span className="text-gray-600 font-medium">Packages:</span>
            <span className="ml-1 text-gray-800 font-semibold">{colli}</span>
          </div>
          <div className="flex items-center col-span-3">
            <span className="text-gray-600 font-medium">Warehouse:</span>
            <span className="ml-1 text-gray-800">{warehouseName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const PoNumberCell = (props) => {
  const { dataItem } = props;
  const poId = dataItem.id; // Assuming `id` is the identifier for the PO
  return (
    <td>
      <a
        href={`#/po/${poId}`}
        target="_blank"
        rel="noopener noreferrer"
        className="!text-blue-600 !underline"
      >
        {dataItem.poNo}
      </a>
    </td>
  );
};

const DATA_ITEM_KEY = "package.id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const CargoImportPopup = ({ quotation, closeModal, onSavePo }) => {
  const columns = [
    {
      field: "poNo",
      title: "Po No",
      show: true,
      width: 150,
      filterable: true,
      cell: PoNumberCell,
    },
    {
      field: "vesselName",
      title: "Vessel",
      show: true,
      width: 150,
      filterable: true,
    },
    {
      field: "warehouseName",
      title: "Warehouse",
      show: true,
      width: 150,
      filterable: true,
    },
    {
      field: "PackageLWH",
      title: "Package",
      show: true,
      width: 150,
      filterable: false,
    },
    {
      field: "package.colli",
      title: "Quantity",
      show: true,
      width: 150,
      filterable: false,
    },
  ];
  const [dataState, setDataState] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState();

  const navigate = useNavigate();

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onRowDoubleClick = (event) => {
    const id = event.dataItem.id;
    if (id) {
      window.open(`#/po/${id}`, "_blank");
    }
  };

  const onHeaderSelectionChange = useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const loadCargoes = async () => {
    setLoading(true);
    const res = await axios.get(
      `${BASE_URL}/Cargo/PoByCustomer?customerId=${
        quotation?.customerId ?? null
      }&vessel=~ALL&warehouse=~ALL`
    );
    let data = res.data.data;
    setDataState(
      data.map((d) => ({
        ...d,
        // packageId: d.package.id,
        PackageLWH: d.package.weight + " Kg /" + d.package.volume + " M3",
      }))
    );
    if (quotation.cargoDetails.cargoes.length > 0) {
      let selectedCargoes = {};
      quotation.cargoDetails.cargoes.forEach((cargo) => {
        selectedCargoes[cargo.packageId] = true;
      });
      setSelectedState(selectedCargoes);
    }
    setLoading(false);
  };

  const onSaveClick = () => {
    const selectedCargoes = dataState.filter(
      (x) => selectedState[x.package.id]
    );
    onSavePo(selectedCargoes);
    closeModal();
  };

  useEffect(() => {
    loadCargoes();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="font-medium flex justify-between">
        Import Cargo
        <div className="flex gap-2">
          <button
            type="button"
            className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-bg-lime-600 shadow-sm text-sm font-semibold rounded-md bg-lime-600 text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            onClick={() => window.open("#/po/new", "_blank")}
          >
            New PO
          </button>
          <button
            type="button"
            className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-sky-600 shadow-sm text-sm font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            onClick={onSaveClick}
          >
            Save
          </button>
          <button
            type="button"
            className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-black shadow-sm text-sm font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            onClick={() => onSaveClick()}
          >
            Close
          </button>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="relative text-xs overflow-hidden border rounded-lg shadow-[0_0_4px_rgba(0,0,0,0.1)]">
          {loading && <KendoLoader />}
          <Grid
            data={filterBy(
              dataState.map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
              })),
              filterState
            )}
            style={{
              height: "400px",
            }}
            filter={filterState}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            filterable
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            onFilterChange={(e) => setFilterState(e.filter)}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            onRowDoubleClick={onRowDoubleClick}
          >
            <Column
              field={SELECTED_FIELD}
              width="60px"
              filterable={false}
              headerSelectionValue={
                dataState.findIndex(
                  (item) => !selectedState[idGetter(item)]
                ) === -1
              }
            />
            {columns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    width={column.width}
                    filterable={column.filterable}
                    cell={column.cell}
                    selectable={true}
                  />
                )
            )}
          </Grid>
        </div>
        <div>
          <div>Selected Cargoes</div>
          <div className="w-96 overflow-y-scroll h-96 pr-2 pl-1 pt-2 pb-5">
            {dataState
              .filter((x) => selectedState[x.package.id])
              .map((x) => (
                <ShippingCard
                  package={x.package}
                  poNo={x.poNo}
                  vesselName={x.vesselName}
                  warehouseName={x.warehouseName}
                  key={x.package.id}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CargoImportPopup;
