import { Disclosure } from "@headlessui/react";
import { Fragment, useState, useContext } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  ChartBarIcon,
  AdjustmentsIcon,
  HomeIcon,
  LightningBoltIcon,
  TruckIcon,
  MenuAlt2Icon,
  XIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  DocumentReportIcon,
  ClipboardCheckIcon,
} from "@heroicons/react/outline";
import AppContext from "../context/app-context";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";

const nav = [
  {
    name: "Home",
    href: "home",
    icon: HomeIcon,
    current: true,
    disabledRole: 300,
  },
  // {
  //   name: "Planner",
  //   href: "planner",
  //   icon: ClipboardCheckIcon,
  //   current: false,
  // },
  { name: "PO", href: "poes/all", icon: LightningBoltIcon, current: false },
  {
    name: "Quotations",
    href: "quotations",
    icon: DocumentReportIcon,
    disabledRole: 300,
    current: false,
  },
  { name: "Jobs", href: "jobs", icon: TruckIcon, current: false },
  {
    name: "Masters",
    href: "",
    icon: AdjustmentsIcon,
    disabledRole: 300,
    current: false,
    children: [
      { name: "User", href: "user", current: false },
      { name: "Customer", href: "customer", current: false },
      { name: "Supplier", href: "supplier", current: false },
      { name: "Agent", href: "agent", current: false },
      { name: "Party", href: "party", current: false },
      { name: "Vessel", href: "vessel", current: false },
      { name: "Warehouse", href: "warehouse", current: false },
      { name: "Port", href: "port", current: false },
      { name: "City", href: "city", current: false },
      { name: "Charge", href: "charge", current: false },
      { name: "Tariff", href: "tariff", current: false },
    ],
  },
  {
    name: "Reports",
    href: "",
    icon: ChartBarIcon,
    current: false,
    children: [
      { name: "Stock", href: "stock", current: false },
      // { name: "Un-Invoiced", href: "uninvoiced", current: false },
    ],
  },
];
const userNavigation = [{ name: "Sign out", href: "#" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children }) {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState(nav);
  const authCtx = useContext(AppContext);
  const onLogout = () => {
    authCtx.logout();
    navigate("/#");
  };
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="h-screen w-screen border-t-4 border-blue-700">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="vml_logo.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation
                      .filter((x) => x.disabledRole !== authCtx.profile.role)
                      .map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={() => {
                            setNavigation((prev) =>
                              prev.map((n) =>
                                n.name === item.name
                                  ? { ...n, current: true }
                                  : { ...n, current: false }
                              )
                            );
                          }}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* {open?*/}
        <div
          className={`${
            open ? "md:w-52 xl:w-64" : "w-20"
          } duration-500 ease-in-out side-bar hidden md:flex md:flex-col md:fixed md:inset-y-0`}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {open ? (
            <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 m-t-4 overflow-y-auto h-full">
              <div
                className="flex items-center flex-shrink-0 px-4 cursor-pointer"
                onClick={() =>
                  navigate(authCtx.profile.role === 300 ? "/po/all" : "/home")
                }
              >
                <img
                  className="h-auto w-40 m-auto mt-5"
                  src="logo.png"
                  alt="VML"
                />
              </div>
              <div className="mt-5 flex-grow flex flex-col">
                <nav className="flex-1 px-2.5 pb-4 space-y-1">
                  {navigation
                    .filter((x) => x.disabledRole !== authCtx.profile.role)
                    .map((item) =>
                      !item.children ? (
                        <div key={item.name}>
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => {
                              setNavigation((prev) =>
                                prev.map((n) =>
                                  n.name === item.name
                                    ? { ...n, current: true }
                                    : { ...n, current: false }
                                )
                              );
                            }}
                            className={classNames(
                              item.current
                                ? "text-blue-600"
                                : "text-gray-500 hover:hover:text-blue-600",
                              "group flex items-center px-2.5 py-2 text-sm font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-blue-600"
                                  : "text-gray-500 group-hover:text-blue-600",
                                "mr-3 flex-shrink-0 h-6 w-5"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        </div>
                      ) : (
                        <Disclosure key={item.name} className="space-y-1">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? "text-blue-600"
                                    : "text-gray-500 hover:hover:text-blue-600",
                                  "w-full group flex items-center px-2.5 py-2 text-sm font-medium rounded-md"
                                )}
                              >
                                <item.icon
                                  className="mr-3 flex-shrink-0 h-6 w-6 text-gray-500 group-hover:text-blue-600"
                                  aria-hidden="true"
                                />
                                <span>{item.name}</span>
                                {open ? (
                                  <ChevronDownIcon
                                    className="ml-auto flex-shrink-0 h-5 w-5 text-gray-500 transform group-hover:text-blue-600 transition-colors ease-in-out duration-150"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronRightIcon
                                    className="ml-auto flex-shrink-0 h-5 w-5 text-gray-500 transform group-hover:text-blue-600 transition-colors ease-in-out duration-150"
                                    aria-hidden="true"
                                  />
                                )}
                              </Disclosure.Button>
                              <Disclosure.Panel className="space-y-1">
                                {item.children.map((subItem) => (
                                  <div
                                    key={subItem.name}
                                    className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-500 rounded-md hover:text-blue-600"
                                  >
                                    <Link
                                      to={subItem.href}
                                      onClick={() => {
                                        setNavigation((prev) =>
                                          prev.map((n) =>
                                            n.name === item.name
                                              ? {
                                                  ...n,
                                                  children: n.children.map(
                                                    (c) =>
                                                      c.name === subItem.name
                                                        ? {
                                                            ...c,
                                                            current: true,
                                                          }
                                                        : {
                                                            ...c,
                                                            current: false,
                                                          }
                                                  ),
                                                }
                                              : n
                                          )
                                        );
                                      }}
                                      className={classNames(
                                        subItem.current
                                          ? "text-blue-600"
                                          : "text-gray-500 hover:hover:text-blue-600"
                                      )}
                                    >
                                      {subItem.name}
                                    </Link>
                                  </div>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    )}
                </nav>
              </div>
            </div>
          ) : (
            <div className="flex flex-col border-r border-gray-200 h-full">
              <img
                className="h-auto w-16 mt-6 mx-auto"
                src="logo.png"
                alt="VML"
              />
              <div className="mt-5 flex flex-col justify-center items-center">
                <nav className="flex-auto px-2.5 pb-4 space-y-8">
                  {navigation
                    .filter((x) => x.disabledRole !== authCtx.profile.role)
                    .map((item) =>
                      !item.children ? (
                        <div key={item.name}>
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => {
                              setNavigation((prev) =>
                                prev.map((n) =>
                                  n.name === item.name
                                    ? { ...n, current: true }
                                    : { ...n, current: false }
                                )
                              );
                            }}
                            className={classNames(
                              item.current === true
                                ? "text-blue-600"
                                : "text-gray-500 hover:hover:text-blue-600",
                              "group flex items-center px-2.5 py-2 text-sm font-medium rounded-md"
                            )}
                          >
                            <div className="" title={item.name}>
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-blue-600"
                                    : "text-gray-500 group-hover:text-blue-600",
                                  "flex-shrink-0 h-6 w-5"
                                )}
                                aria-hidden="true"
                              />
                            </div>{" "}
                          </Link>
                        </div>
                      ) : (
                        <Disclosure key={item.name} className="space-y-1">
                          {({ open }) => (
                            <>
                              <div className="flex">
                                <Disclosure.Button
                                  className={classNames(
                                    item.current
                                      ? "text-blue-600"
                                      : "text-gray-500 hover:hover:text-blue-600",
                                    "w-full group flex items-center justify-center px-2.5 py-2 text-sm font-medium rounded-md"
                                  )}
                                >
                                  <item.icon
                                    className="flex-shrink-0 h-6 w-6 text-gray-500 group-hover:text-blue-600"
                                    aria-hidden="true"
                                  />
                                  <ChevronRightIcon
                                    className=" flex-shrink-0 h-5 w-5 text-gray-500 transform group-hover:text-blue-600 transition-colors ease-in-out duration-150"
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1  fixed left-20 bg-white border-2  w-36 flex-row text-sm font-medium text-gray-500 rounded-md ">
                                  {item.children.map((subItem) => (
                                    <div
                                      key={subItem.name}
                                      className="group overflow-visible hover:text-blue-600 pl-5 pr-2 py-2 m-2"
                                    >
                                      <Disclosure.Button>
                                        <Link
                                          to={subItem.href}
                                          onClick={() => {
                                            setNavigation((prev) =>
                                              prev.map((n) =>
                                                n.name === item.name
                                                  ? {
                                                      ...n,
                                                      children: n.children.map(
                                                        (c) =>
                                                          c.name ===
                                                          subItem.name
                                                            ? {
                                                                ...c,
                                                                current: true,
                                                              }
                                                            : {
                                                                ...c,
                                                                current: false,
                                                              }
                                                      ),
                                                    }
                                                  : n
                                              )
                                            );
                                          }}
                                          className={classNames(
                                            subItem.current
                                              ? "text-blue-600"
                                              : "text-gray-500 hover:hover:text-blue-600"
                                          )}
                                        >
                                          {subItem.name}
                                        </Link>
                                      </Disclosure.Button>
                                    </div>
                                  ))}
                                </Disclosure.Panel>
                              </div>{" "}
                            </>
                          )}
                        </Disclosure>
                      )
                    )}
                </nav>
              </div>
            </div>
          )}
        </div>
        <div
          className={`${
            open ? "xl:pl-64 md:pl-52" : "xl:pl-20 md:pl-20"
          } h-screen duration-500 ease-in-out flex flex-col flex-1 w-full`}
        >
          <div className="sticky top-0 flex-shrink-0 flex h-16 bg-white shadow pl-2">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              onClick={() => setOpen(!open)}
              className=" hidden md:block text-[#7d838f]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                ></path>
              </svg>
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">{/* <SearchBar /> */}</div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon
                    className="h-8 w-8 outline rounded-full outline-[1px] p-1"
                    aria-hidden="true"
                  />
                </button> */}

                {/* Profile dropdown */}

                <Menu as="div" className="ml-3 mr-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
                          <span className="sr-only">Open user menu</span>
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-500">
                            <span className="text-lg  leading-none text-white">
                              {authCtx.profile.displayname
                                .toString()
                                .substring(0, 1)}
                            </span>
                          </span>
                          <div className="text-left pl-1.5">
                            <p className="font-bold text-black-600">
                              {authCtx.profile.displayname}
                            </p>
                            <p className="text-gray-600 text-xs">
                              {authCtx.profile.jobTitle}
                            </p>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        {open && (
                          <Menu.Items className="origin-top-right absolute z-70 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name} onClick={onLogout}>
                                {({ active }) => (
                                  <NavLink
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        )}
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <button
                type="button"
                className=" flex items-center"
                onClick={onLogout}
              >
                <img src="logout.svg" className="h-5 w-5" />
              </button>
            </div>
          </div>

          <main className="flex-1 h-full w-full lg:overflow-hidden overflow-y-auto bg-gray-100">
            {children}
          </main>
        </div>
      </div>
    </>
  );
}
